import React from "react";
import "./widget.css";

const Widget = ({ title, subTitle=null, className=null, children }) => {
    return (
        <div className={className ? "widget " + className : "widget" }>
            <div className="widget-header">
                {title && <h1>{title}</h1>}
                {subTitle && <p>{subTitle}</p>}
            </div>
            {children}
        </div>
    );
}

export default Widget;
