

import React, { useState } from "react";
import axios from "axios";
import Alert from "../../components/UI/alert/Alert"
import Modal from "../../components/UI/modal/Modal"

import { useSelector, useDispatch } from "react-redux";
import { setTechnologiesList } from "../../redux/slices/technologiesSlice"
import { setPopUpAlert } from "../../redux/slices/alertSlice"
import { getErrorMessage } from "../../util/functions/getErrorMessage";
import { formatTechnologiesListWithTopics } from "../../util/functions/formatTechnologiesListWithTopics";
import { setCyberSecurityTopics } from "../../redux/slices/cyberSecurityTopicsSlice"

const DeleteTechnology = (props) => {

  
  const [deleteData, setDeleteData] = useState(props.selectedTechnologyItemData)
  
  const [deleteAlertMessage, setDeleteAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  
  const dispatch = useDispatch()

  const handleDeleteCancel = () => {
    props.closeModalResetData()
    setDeleteAlertMessage("")
    setLoading(false)
  }

  const handleDeleteFormData = async (data) => {
    setLoading(true)
    try {

      const deleteTechnologyRes = await axios.post(
        `/business/technologies/deleteTechnology`,
        {
          vendor_id: deleteData.vendor_id
        }

      );
      const cyberSecurityRes = await axios.get("/business/cyberSecurity/cyberSecurityTopics");
      dispatch(setTechnologiesList(formatTechnologiesListWithTopics(deleteTechnologyRes.data.vendors, cyberSecurityRes.data)))
      dispatch(setCyberSecurityTopics(cyberSecurityRes.data))
      // update technologies List 
      
      dispatch(setPopUpAlert({ message: "Success! Technology deleted.", type: "success", length: 2000 }))
      props.setTechnologiesListAccordionsState((prev) => {
        const newState = new Map(prev);
        newState.delete(deleteData.vendor_id);
        return newState;
      })
      handleDeleteCancel()

    } catch (error) {

      if (error) {
        const errorMessage = getErrorMessage(error)

            if (errorMessage === "Successfully deleted technology. An error occured while getting the list of technologies. Please try to refresh the page or try again soon.") {
                handleDeleteCancel()
                dispatch(setPopUpAlert({ message: errorMessage, type: "error", length: 7000 }))
            } else {
                setLoading(false)
                setDeleteAlertMessage({ message: errorMessage, type: 'error' })
            }

        }
    }

  }


  return (
    <Modal
      title={"Delete Technology"}
      onCancel={() => handleDeleteCancel()}
      onConfirm={() => handleDeleteFormData()}
      submitText="Yes"
      loading={loading}
      confirmDisabled={
        loading ? true : false
      }
    >
      <span>Are you sure you want to delete {deleteData?.name}?</span>

      <Alert type={deleteAlertMessage.type} message={deleteAlertMessage.message} showIcon={true} />
    </Modal>
  );
};

export default DeleteTechnology;

