import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./multiRangeSlider.css";

const MultiRangeSlider = (props) => {
  const min = props.min
  const max = props.max

  const [minVal, setMinVal] = useState(props.chartRange.minVal);
  const [maxVal, setMaxVal] = useState(props.chartRange.maxVal);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  const _handleMouseUp = () => {
    if (minVal != props.chartRange.minVal || maxVal != props.chartRange.maxVal) {
      props.onMouseRelease({
        minVal: minVal,
        maxVal: maxVal
      })
    }
  }

  function _getMarkerList() {
    let markerList = []
    for (var i = props.min; i <= props.max; i++) {
      markerList.push(<option key={i} value={i} label={`${i}`} />)
    }
    return markerList
  };

  return (
    <div className="container">
      <div className="slider-text">
        <span>Currently showing level from {minVal} to {maxVal}</span>
      </div>
      <div className="component">
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          ref={minValRef}
          onChange={(event) => {
            const value = Math.min(+event.target.value, maxVal - 1);
            setMinVal(value);
          }}
          onMouseUp={_handleMouseUp}
          className="thumb thumb--zindex"
          list="marker-list"
        />
        <input
          type="range"
          min={min}
          max={max}
          value={maxVal}
          ref={maxValRef}
          onChange={(event) => {
            const value = Math.max(+event.target.value, minVal + 1);
            setMaxVal(value);
          }}
          onMouseUp={_handleMouseUp}
          className="thumb thumb--zindex thumb-2"
          list="marker-list"
        />
        <datalist id="marker-list">{_getMarkerList()}</datalist>

        <div className="slider">
          <div className="slider__track" />
          <div ref={range} className="slider__range" />
          <div className="slider__min-max-value">
            <span>{min}</span>
            <span>{max}</span>
          </div>
          {/* <div className="slider__left-value">{min}</div>
              <div className="slider__right-value">{max}</div> */}
        </div>
      </div>
    </div>
  );
};

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onMouseRelease: PropTypes.func.isRequired
};

export default MultiRangeSlider;
