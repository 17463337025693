import React, { useState } from "react";
import axios from "axios";
import Sign from "../../components/sign/Sign";
import { getSafe } from "../../util/functions/getSafe";

const ForgotPassword = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [ type, setType ] = useState("forgotpassword");

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    const formData = {
      email: e.target[0].value,
    };

    setLoading(true);
    try {
      let res = await axios.post("/business/account/forgotPassword", {
        email: formData.email,
      });
      setType("forgotpassword_sent")

    } catch (error) {
      setLoading(false);
      const msg = getSafe(() => error.response.data.message, "Something went wrong, please try again after sometime.");
      setErrorMessage(msg);
    }
  };

  return (
    <Sign
      title="Forgot Password"
      type={type}
      formComplete={handleForgotPassword}
      errorMessage={errorMessage}
      loading={loading}
    />
  );
};

export default ForgotPassword;