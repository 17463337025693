import { createSlice } from '@reduxjs/toolkit'


export const CyberSecurityTopicsSlice = createSlice({
  name: "cyberSecurityTopics",
  initialState: {
    cyberSecurityTopics: null,
    topicsWithSubTopics: []
  },



  reducers: {

    setCyberSecurityTopics: (state, action) => {
      state.cyberSecurityTopics = action.payload



    },
    topicsWithSubTopics: (state, action) => {

      let topicsWithSubTopicsList = []
      const formatter = (data) => {
    
        data.forEach((subtopic, i) => {

          if (subtopic.sub_topics[0]) {
            topicsWithSubTopicsList.push(subtopic.cybersecurity_topic_id)
          }

          formatter(subtopic.sub_topics);
        })

      };
      formatter(action.payload.cybersecurity_topic_summary);
      state.topicsWithSubTopics = topicsWithSubTopicsList
    },
  },
})

export const { setCyberSecurityTopics, topicsWithSubTopics} = CyberSecurityTopicsSlice.actions

export default CyberSecurityTopicsSlice.reducer