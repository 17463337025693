
export const formatTechnologiesListWithTopics = (technologies, topics) => {
    let cyberSecurityTopicList = structuredClone(topics);
    let technologiesList = structuredClone(technologies);

    let formattedCyberSecurityData;

    formattedCyberSecurityData = {
        name: "cybersecurity_topic_summary",
        sub_topics: cyberSecurityTopicList["cybersecurity_topic_summary"],
    };

    technologiesList.forEach(el => {
        el.vendor["topics"] = []
    })

    //looping through every nested layer
    const formatter = (data) => {

        data.sub_topics.forEach((subtopic, i) => {
            subtopic.vendor_assocs && subtopic.vendor_assocs[0] && subtopic.vendor_assocs.forEach((vendor) => {

                technologiesList.forEach(el => {

                    if (el.vendor.vendor_id === vendor.vendor_id) {

                        return el.vendor.topics.push({
                            name: subtopic.name,
                            cybersecurity_topic_id: subtopic.cybersecurity_topic_id,
                            note: subtopic.cybersecurity_topic_status.note,
                            status: subtopic.cybersecurity_topic_status.state,
                            topic_hierarchy: subtopic.topic_hierarchy,
                            vendor_id: vendor.vendor_id,
                            vendor_name: vendor.name
                            // { value: "priority", header: "Priority" },

                        })
                    }
                    return
                })
            })
            formatter(subtopic);
        });

        return data
    };
    formatter(formattedCyberSecurityData);
    return technologiesList
}