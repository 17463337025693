import env from "./env";

// returns the full image URL
// adds the base url of the image service to a url path

const imageUrl = path => {
    const pathNoLeadSlash = path.charAt(0) === "/" ? path.slice(1) : path;
    const baseUrl = env("CISOBOT_IMAGES_URL_BASE");
    const baseUrlTrailSlash = baseUrl.charAt(baseUrl.length - 1) === "/" ? baseUrl : baseUrl + "/";

    return baseUrlTrailSlash + pathNoLeadSlash;
}

export default imageUrl;