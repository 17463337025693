import React, { useState, useEffect } from "react";
import * as uuid from 'uuid';
import Modal from "../../../components/UI/modal/Modal"
import { PerformRequest } from "../../../util/performRequest";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setTechnologiesList } from "../../../redux/slices/technologiesSlice"
import { setPopUpAlert } from "../../../redux/slices/alertSlice"
import { getErrorMessage } from "../../../util/functions/getErrorMessage";
import { reset } from "../../../redux/slices/tableSlice";
import { setCyberSecurityTopics } from "../../../redux/slices/cyberSecurityTopicsSlice"


const checkboxPerformRequest = new PerformRequest();
const UnassociateTechnologyTopicModal = (props) => {

    const [loading, setLoading] = useState(false);

    const technologyTopicUnassociateData = useSelector((state) => state.table.actionRowData);

    const technologiesListData = useSelector((state) => state.technologies.technologiesList);

    const dispatch = useDispatch()

    const handleTechnologyTopicUnassociate = async () => {
        setLoading(true)

        let echoId = uuid.v4()
        const reqObj = { reqDetails: { endpoint: "/business/associate/technologyTopicAssociation", reqType: "post" }, headers: { ["echo-id"]: echoId }, reqBody: { is_associated: true, cybersecurity_topic_id: technologyTopicUnassociateData.cybersecurity_topic_id, vendor_id: technologyTopicUnassociateData.vendor_id } }
        const callback = async (reqObj, resObj) => {

            if (resObj.status === 200) {

                if (echoId === resObj.headers["echo-id"]) {
                    dispatch(setCyberSecurityTopics(resObj.data))
                    dispatch(setTechnologiesList(props.formatTechnologiesListWithTopics(technologiesListData, resObj.data)))
                    dispatch(setPopUpAlert({ message: "Success!", type: "success", length: 2000 }))
                    handleCancel()
                }
            } else {
                if (echoId === resObj.response.headers["echo-id"]) {
                    handleCancel()
                    dispatch(setPopUpAlert({ message: getErrorMessage(resObj), type: "error", length: 8000 }))
                    response = {
                        checkbox: !isChecked,
                        disabled: false
                    }
                }
            }
        }
        checkboxPerformRequest.performRequest(reqObj, callback)


    }

    const handleCancel = () => {
        dispatch(reset())
        setLoading(false)
    }


    console.log(technologyTopicUnassociateData)
    return (
        <Modal
            title={`Unassociate topic and technology`}
            onCancel={() => handleCancel()}
            onConfirm={() => handleTechnologyTopicUnassociate()}

            loading={loading}
            confirmDisabled={
                loading ? true : false
            }
        >
            <span>Are you sure you want to unassociate {technologyTopicUnassociateData.vendor_name} and {technologyTopicUnassociateData.name}?</span>
        </Modal>

    );
};

export default UnassociateTechnologyTopicModal
