import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import alertReducer from "../slices/alertSlice";
import requestReducer from "../slices/requestSlice";
import sidebarReducer from "../slices/sidebarSlice";
import userReducer from "../slices/userSlice";
import tableReducer from "../slices/tableSlice";
import cyberSecurityTopicsReducer from "../slices/cyberSecurityTopicsSlice";
import technologiesReducer from "../slices/technologiesSlice";




let reducers = combineReducers({
    alert: alertReducer,
    request: requestReducer,
    sidebar: sidebarReducer,
    user: userReducer,
    table: tableReducer,
    cyberSecurityTopics: cyberSecurityTopicsReducer,
    technologies: technologiesReducer,
 
})

const rootReducer = (state, action) => {
    if (action.type === "user/signOut") {
        // reset to initial state for all reducers
        localStorage.removeItem('persist:root')
        return reducers(undefined, action);
    }
    return reducers(state, action);
};

export default persistReducer(
    {
        key: "root",
        storage,
    },
    rootReducer
);