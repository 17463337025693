import axios from "axios";
import * as uuid from 'uuid';

export class PerformRequest {
    constructor() {

        this.requestsInFlight = {};
    }
    performRequest = async (reqObj, callback) => {
        if (reqObj) {

            //set the echo id in the header of the reqObj
            let echoId

            if (!reqObj["headers"]["echo-id"]) {
                echoId = uuid.v4()
            } else {
                echoId = reqObj["headers"]["echo-id"]
            }

            reqObj["headers"]["echo-id"] = echoId
            //add echoId and callback as a key value pair to the requestInflight object
            this.requestsInFlight[echoId] = callback

        }


        try {
            //use reqObject parameter to handle all the details in the axios call
         
            const res = await axios[reqObj.reqDetails.reqType](
                reqObj.reqDetails.endpoint,
                reqObj.reqBody, {
                headers: reqObj.headers
            }
            );

            // the response header will have the same echo id that was sent in the request header
            // find this response echo id in the requestInFlight object and use the callback associated with it
            let respUid = res.headers["echo-id"]
            let callBack = this.requestsInFlight[respUid]
            callBack(reqObj, res)
            //after we call the call back remove the key value pair from the requestsInFlight object
            delete this.requestsInFlight[res.headers["echo-id"]]
        } catch (error) {

            if (error && error.response && error.response.headers && error.response.headers['echo-id']) {
                let respUid = error.response.headers["echo-id"]
                let callBack = this.requestsInFlight[respUid]
                callBack(reqObj, error)
                delete this.requestsInFlight[error.response.headers["echo-id"]]

            }
        }

    };

    getRequestsInFlight = () => {
        return this.requestsInFlight
    }


}



