export const handleStatusColor = (status) => {
    if (status === "resolved") {
      return "#26D701";
    } else if (status === "in_progress") {
      return "#FF8E15";
    } else if (status === "to_do") {
      return "#FF0D0D";
    } else if (status === "not_applicable") {
      return "#C3C3C1";
    }
  }