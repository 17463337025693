import React, { useEffect, useState } from "react";

import TableHeadItem from "./TableHeadItem"
import TableRow from "./TableRow"

// - Props
// - initialSort --> contains the name of the column the table will initially be sorted by
// - data  --> table data
// - columns --> columns of the table
// - collapsible --> true or false value. if true a column  will be created to toggle collapsible data. The collapsibleColumns prop will be used to display this colapsible data
// - collapsibleColumns --> columns used for the collapsible columns
// - noDataHeader --> header for the table when there is no data
// - noDataBOdy --> text being displayed in the table body  when there is no data
// - name --> name of the table used as the className for the table element
// - colSpan --> used in the collapsible row - sets the width of the collapsible row

const Table = ({ initialSort = null, data = null, columns = null, collapsibleColumns = null, noDataHeader, noDataBody = "No Data", style, collapsible = false, name,colSpan,hover, striped, }) => {
    const [formattedColumns, setformattedColumns] = useState([])

    const [expandedRows, setExpandedRows] = useState(new Map())

    useEffect(() => {

        if (columns && columns[0]) {
            let newColumnsArray = columns

            // handling table data when collapsible is set to true
            if (collapsible) {
                         //create an array of boolian values to keep track of which rows are expanded
                data && setExpandedRows(new Map(data.map((object, index) => {
                    return [`${name}-${index}`, false];
                })))
                // create new column "collapse" for expanding the rows"
                if(newColumnsArray[0].value !== "collapse" )
                newColumnsArray.unshift({ value: "collapse" })

                setformattedColumns(newColumnsArray)
            } else {
                setformattedColumns(columns)
            }

        }
    }, [])


    const handleTableHeader = () => {
        if (!data) {
            return <TableHeadItem style={{ fontSize: "14px" }} noDataHeader={noDataHeader} />;
        }
        if (data && !data[0]) {
            return <TableHeadItem style={{ fontSize: "14px" }} noDataHeader={noDataHeader} />;
        }
        if (data && data[0]) {
            return (
                formattedColumns && formattedColumns.map((item, index) => {

                    return (
                        <React.Fragment key={index}>
                            <TableHeadItem index={index} item={item} />
                        </React.Fragment>
                    )
                })
            )
        }

    }

    const handleTableBody = () => {
        if (!data) {
            return <tr ><td style={{ fontSize: "14px", textAlign: "center", padding: "10px" }}>{noDataBody}</td></tr>
        }
        if (data && !data[0]) {
            return <tr ><td style={{ fontSize: "14px", textAlign: "center", padding: "10px" }}>{noDataBody}</td></tr>
        }
        if (data && data[0]) {

            // sort table based on initialSort value
            let sortedData = data
            if (initialSort) {

                sortedData = [...data].sort((a, b) => {

                    let fa = a[initialSort].toLowerCase(),
                        fb = b[initialSort].toLowerCase();

                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });

            }

            return (
                sortedData && sortedData.map((item, index) => {
               
                    return (
                        <React.Fragment key={index}>
                            <TableRow key={index} rowIndex={index} expandedRows={expandedRows} setExpandedRows={setExpandedRows} name={name} hover={hover} striped={striped} columns={formattedColumns && formattedColumns} item={item}  ></TableRow>

                            {expandedRows.get(`${name}-${index}`) &&
                                <TableRow colSpan={colSpan} name={name} rowIndex={index} collapsibleColumns={collapsibleColumns} item={item}  ></TableRow>
                            }
                        </React.Fragment>
                    )
                })
            )
        }
    }

    return (
        <table id={name} style={{ ...style }}>
            <thead>
                <tr>
                    {handleTableHeader()}
                </tr>
            </thead>
            <tbody>
                {handleTableBody()}
            </tbody>
        </table>
    )
}

export default Table;
