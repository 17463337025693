import React, { useState, useEffect } from "react";
import "./select.css";

// options => [{ value: "example", name: "Example" }, { value: "another", name: <p>Another</p> }]
// defaultValue => an option's value
// onChange => a callback function, sends the new value as an argument
// style => any custom styles for the select-container

const Select = ({ options, defaultValue, onChange, style }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [selectedName, setSelectedName] = useState("");
  const [showMenu, setShowMenu] = useState(false);

  // if user clicks anywhere else, close the menu
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        document.querySelector(".select-container").contains(e.target) === false
      ) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // set the name of the selected option to state when selection changes
  useEffect(() => {
    const selectedOption = options.filter(
      (option) => option.value === selectedValue
    );

    setSelectedName(selectedOption[0].name);
  }, [selectedValue]);

  const handleChange = (option) => {
    setSelectedValue(option.value);
    setSelectedName(option.name);

    setShowMenu(false);
    onChange(option.value);
  };

  return (
    <div className="select-container" style={style}>
      <div className="select-box" onClick={() => setShowMenu(!showMenu)}>
        {selectedName}
        <i className={showMenu ? "fas fa-angle-up" : "fas fa-angle-down"} />
      </div>
      {showMenu ? (
        <div className="select-menu-outer">
          <div className="select-menu-inner">
            {options.map((option, key) => {
              let className = "select-menu-item";
              if (option.value === selectedValue) {
                className += " selected";
              }
              return (
                <div
                  key={key}
                  className={className}
                  onClick={() => handleChange(option)}
                >
                  {option.name}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Select;
