import React, { useRef, useLayoutEffect, useEffect, useState } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { handleStatusColor } from "../../../../util/functions/handleStatusColor";
import { handleCriticality } from "../../../../util/functions/handleCriticality";

const SunBurstChart = ({ cyberSecurityTopics, handleTopicSelected }) => {
  const [chartHeight, setChartHeight] = useState("700px");
  const [leafNodeCount, setLeafNodeCount] = useState(null);
  const [chartDepth, setChartDepth] = useState(1);
  const chartRef = useRef(null);


  const dataFormatter = () => {
    let data = structuredClone(cyberSecurityTopics);

    let formattedData;
    formattedData = {
      name: "cybersecurity_topic_summary",
      sub_topics: data["cybersecurity_topic_summary"],
    };

    //looping through every nested layer
    let leafNodes = 0;

    const formatter = (data, depth = 0) => {
      depth++;
      data.sub_topics.forEach((subtopic) => {
        subtopic.formattedName = subtopic.name

        subtopic.priority = Number(subtopic.priority); //change priority string to number so chart will work

        subtopic.formattedPriority = formatPriority(
          subtopic.scalePriority
        );
        subtopic.formattedState = formatState(
          subtopic.cybersecurity_topic_status.state
        );

        //giving every slice a value of 10 so every slice in the chart has the same width
        if (!subtopic.sub_topics.length) {
          subtopic.value = 10
          leafNodes = leafNodes + 1
        }

        formatter(subtopic, depth);
      });
      return data;
    };
    const formatState = (state) => {
      let status = state?.replace("_", " ");
      let statusValue = status.charAt(0).toUpperCase() + status.slice(1);
      return statusValue;
    }

    const formatPriority = (scalePriority) => {
      let priorityValue = handleCriticality(scalePriority)?.criticality;
      if (priorityValue) return `\n [bold]Priority:[/] ${priorityValue}`;
      return "";
    }

    formatter(formattedData);

    setLeafNodeCount(leafNodes);

    return formattedData;
  };

  useEffect(() => {
    handleChartHeight()
  }, [leafNodeCount])

  const handleChartHeight = () => {

    const interval = 18; // the height to add as leafNodeCount increases
    const initHeight = 600;
    const maxHeight = window.innerWidth * 0.9; // chart does not scale past this point because its circular

    let height = (Math.round(leafNodeCount / 2) * interval) + initHeight;

    if (height > maxHeight) {
      height = maxHeight;
    }
    // height = height.toString() + "px";

    if (window.innerWidth < height) {
      setChartHeight(window.innerWidth + "px");
    } else {
      setChartHeight(height.toString() + "px");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleChartHeight);
    return () => window.removeEventListener("resize", handleChartHeight);
  });

  useLayoutEffect(() => {
    let root = am5.Root.new("sunburstChart");

    const myTheme = am5.Theme.new(root);

    myTheme
      .rule("ColorSet")
      .set("colors", [am5.color(0x5aaa95), am5.color(0x86a873)]);


    root.setThemes([am5themes_Animated.new(root), myTheme]);

    let chart = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    );


    let series = chart.children.push(
      am5hierarchy.Sunburst.new(root, {
        topDepth: 1,
        downDepth: 10,
        initialDepth: 10,
        valueField: "value",
        // categoryField: "formattedName",
        childDataField: "sub_topics",
        innerRadius: am5.percent(20),
      })
    );

    let tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      autoTextColor: false,
      labelText: "[bold]{name}[/]\n [bold]Status:[/] {formattedState} {formattedPriority}",
    });

    tooltip.get("background").setAll({
      fill: am5.color(0x555555),
    });



    tooltip.label.setAll({
      fill: am5.color(0xffffff)
    });

    series.set("tooltip", tooltip);

    //turns of interaction with the chart
    series.nodes.template.setAll({
      toggleKey: "none",
      cursorOverStyle: "pointer",
    });

    series.slices.template.setAll({
      fillOpacity: 1,
    });

    // Add hover effect
    series.slices.template.events.on("hover", (event) => {
      const slice = event.target;
      if (slice.isActive || slice.isHiding || slice.isShowing) {
        return;
      }
      slice.setState("hover");
    });

    // Remove hover effect
    series.slices.template.events.on("out", (event) => {
      const slice = event.target;
      if (slice.isActive || slice.isHiding || slice.isShowing) {
        return;
      }
      slice.setState("default");
    });

    series.bullets.push(function (root, series, dataItem) {
      let status = dataItem.dataContext?.cybersecurity_topic_status?.state

      if (status && status !== "default") {

        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 0.95,
          sprite: am5.Circle.new(root, {
            radius: 4,
            fill: am5.color(handleStatusColor(status))
          })
        });
      }
    });






    // forces the second layer to have a lower opacity
    series.slices.template.adapters.add("opacity", function (opacity, target) {
      if (target._dataItem._settings.depth > 1) {
        return 0.8;
      }
    });

    series.labels.template.setAll({
      paddingLeft: 20,
      paddingRight: 20,
      oversizedBehavior: "truncate",
      maxWidth: 150,
      text: "{formattedName}"

    });

    series.slices.template.events.on("click", function (e) {
      const dataContext = e.target.dataItem.dataContext;

      handleTopicSelected({
        name: dataContext.name,
        cybersecurity_topic_id: dataContext.cybersecurity_topic_id,
        cybersecurity_topic_status: dataContext.cybersecurity_topic_status,
      });
    });

    chartRef.current = series;

    return () => {
      root.dispose();
    };
  }, []);

  useLayoutEffect(() => {
    if (cyberSecurityTopics) {
      let data = dataFormatter();
      chartRef.current.data.setAll([data]);
      chartRef.current.set("selectedDataItem", chartRef.current.dataItems[0]);
    }
  }, [cyberSecurityTopics]);

  return (
    <div>
      <div
        id="sunburstChart"
        style={{ width: "100%", height: chartHeight }}
      ></div>
    </div>
  );
};
export default SunBurstChart;
