import React from "react";

const TableHeadItem = ({ item, noDataHeader=null, style={}}  ) => {

    return (
        <th className={`${noDataHeader ? noDataHeader : item.value}-col-header`}style={style} >
            <b>{noDataHeader ? noDataHeader :item.header}</b>
        </th>
    );
};

export default TableHeadItem;