import axios from "axios";
// import { message } from "antd";
import { signOut } from "../redux/slices/userSlice";
import { lastRequestUrl } from "../redux/slices/requestSlice";
import { getSafe } from "./functions/getSafe";


export default {
    setup: store => {
        axios.interceptors.request.use(
            request => {         
                store.dispatch(lastRequestUrl(request.url))
                return request;
            },
            error => {
                return Promise.reject(error);
            }
        );
        axios.interceptors.response.use(
            response => {
                if (response.config.url !== "/business/account/login" && response.config.url !== "/business/support/slogin" && response.data.primary_role === 'auditor') {
                    window.location.href = "/auditor"
                }
                return response;
            },
            error => {
                let messageType = "error";

                if (
                    getSafe(() => error.response.status) === 401
                ) {
                    sessionStorage.clear()
                    localStorage.clear()
                    store.dispatch(signOut())
                    messageType = "warning";
                } 

                // NOTE: create a custom message component instead of Antd later

                // message[messageType](
                //     getSafe(
                //         () => error.response.data.message,
                //         "An unknown error occured, please try again soon."
                //     )
                // );

                return Promise.reject(error);
            }
        );
    }
};
