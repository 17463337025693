

import React, { useState } from "react";
import "./vCisoProfileCard.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import env from "../../../util/functions/env"

const VCisoProfileCard = ({ profile, handleProfileClick }) => {
    const [loading, setLoading] = useState(true);


    const imageOnErrorHandler = (event) => {
        event.currentTarget.src = `${env("CISOBOT_IMAGES_URL_BASE")}i/Portrait_Placeholder.png`
        event.currentTarget.className = "error"
    };

    const handleImage = (data) => {
        if (data) {
            return profile.profile.image_url
        } else {
            return `${env("CISOBOT_IMAGES_URL_BASE")}i/Portrait_Placeholder.png`
        }
    }

    return (

        <div className="vciso-card" >
            <div className="vciso-card-img">
                <div style={{ display: loading ? "block" : "none" }}>
                </div>
                <img style={{ display: loading && "none" }} src={handleImage(profile.profile.image_url)}
                    onError={imageOnErrorHandler}
                    onLoad={() => setLoading(false)}
                />
            </div>
            <div className="vciso-card-body">
                <div className="vciso-card-title">
                    {profile.profile.first_name} {profile.profile.last_name}
                </div>
                <div className="vciso-card-email">
                    {profile.profile.email_address}
                </div>
                <div className="vciso-card-title-line">
                    {profile.profile.title_line}
                </div>

                <div className="vciso-card-more-info" onClick={() => handleProfileClick(profile)}>
                    <FontAwesomeIcon icon={faChevronRight} /> <span> Find out more</span>
                </div>
            </div>
        </div>
    );
};

export default VCisoProfileCard;

