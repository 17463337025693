import axios from "axios";
import store from "../../redux/store";
import { signOut } from "../../redux/slices/userSlice";

export const handleSignout = async () => {
  try {
    await axios.post("/business/account/signout");
  } catch (error) {}
  sessionStorage.clear();
  localStorage.clear();
  store.dispatch(signOut());
};
