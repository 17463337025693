import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Widget from "../../components/UI/widget/Widget";
import Loading from "../../components/UI/loading/Loading";
import AddTechnology from "./AddTechnology";
import EditTechnology from "./EditTechnology"
import DeleteTechnology from "./DeleteTechnology";
import TechnologiesList from "./technologiesList/TechnologiesList"
import UnassociateTechnologyTopicModal from "./technologiesList/UnassociateTechnologyTopicModal";

import { getErrorMessage } from "../../util/functions/getErrorMessage";
import { useSelector, useDispatch } from "react-redux";
import { setPopUpAlert } from "../../redux/slices/alertSlice"
import { setTechnologiesList } from "../../redux/slices/technologiesSlice"
import { reset } from "../../redux/slices/tableSlice"
import { setCyberSecurityTopics } from "../../redux/slices/cyberSecurityTopicsSlice"
import { formatTechnologiesListWithTopics } from "../../util/functions/formatTechnologiesListWithTopics";

const Technologies = () => {


    const showAction = useSelector((state) => state.table.showAction);
    const topicsWithSubTopics = useSelector((state) => state.cyberSecurityTopics.topicsWithSubTopics);

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedTechnologyItemData, setSelectedTechnologyItemData] = useState(null);
    const [technologiesListAccordionsState, setTechnologiesListAccordionsState] = useState(new Map());
    const dispatch = useDispatch()


    //############################ Technology List ############################
    const getTechnologiesList = async () => {

        try {
            let res = await axios.get("/business/technologies/technologiesList");
            const cyberSecurityRes = await axios.get("/business/cyberSecurity/cyberSecurityTopics");

            dispatch(setTechnologiesList(formatTechnologiesListWithTopics(res.data.vendors, cyberSecurityRes.data)))

            setTechnologiesListAccordionsState(new Map(res.data.vendors.map(object => {

                return [object.vendor.vendor_id, { technologiesListAccordionToggle: false, associateTopicsAccordionToggle: false, securityTopicsAccordionToggle: [] }];
            })))

            setPageLoading(false);

        } catch (error) {

            dispatch(setPopUpAlert({ message: getErrorMessage(error), type: "error", length: 5000 }))
            setPageLoading(false);
        }
    }

    useEffect(() => {
        dispatch(reset())
        getTechnologiesList();
    }, []);



    //################################# Technologies List Toggle ####################################
    //handles the open/close state of the technologies list accordion  


    const handleTechnologiesAccordionToggle = (key, value) => {

        if (value?.technologiesListAccordionToggle && value?.technologiesListAccordionToggle) {
            setTechnologiesListAccordionsState((prev) => new Map(prev).set(key, { ...technologiesListAccordionsState.get(key), technologiesListAccordionToggle: false }))
        } else {
            setTechnologiesListAccordionsState((prev) => new Map(prev).set(key, { ...technologiesListAccordionsState.get(key), technologiesListAccordionToggle: true }))
        }
    };

    //handles the open/close state of the security topics list accordion
    const handleAssociateTopicsAccordionToggle = (key, value) => {

        if (value) {
            setTechnologiesListAccordionsState((prev) => new Map(prev).set(key, { ...technologiesListAccordionsState.get(key), associateTopicsAccordionToggle: false }))
        } else {
            setTechnologiesListAccordionsState((prev) => new Map(prev).set(key, { ...technologiesListAccordionsState.get(key), associateTopicsAccordionToggle: true }))
        }
    };

    //handles the open/close state of  security topics accordions
    const handleSecurityTopicAccordionToggle = (key, topic, handleAllTopics) => {

        if (handleAllTopics !== undefined) {
            //sets all security topics to an open state
            if (handleAllTopics === "open") {
                setTechnologiesListAccordionsState((prev) => new Map(prev).set(key, { ...technologiesListAccordionsState.get(key), securityTopicsAccordionToggle: [...topicsWithSubTopics] }))
            } else {
                //sets all security topics to a closed state
                setTechnologiesListAccordionsState((prev) => new Map(prev).set(key, {
                    ...technologiesListAccordionsState.get(key), securityTopicsAccordionToggle: []
                }))
            }

        } else {
            //sets s sigle  security topic to an open state
            if (!technologiesListAccordionsState.get(key).securityTopicsAccordionToggle.includes(topic)) {

                setTechnologiesListAccordionsState((prev) => new Map(prev).set(key, { ...technologiesListAccordionsState.get(key), securityTopicsAccordionToggle: [...technologiesListAccordionsState.get(key).securityTopicsAccordionToggle, topic] }))

            } else {
                //sets s single security topic to an closed state
                let newList = technologiesListAccordionsState.get(key).securityTopicsAccordionToggle.filter(function (ele) {
                    return ele != topic
                })
                setTechnologiesListAccordionsState((prev) => new Map(prev).set(key, {
                    ...technologiesListAccordionsState.get(key), securityTopicsAccordionToggle: newList
                }))
            }

        }
    };


    //############################ Add Vendor ############################

    const handleAddFormSubmit = async (e, addTechnologyFormData, setAddTechnologyFormData) => {
        e.preventDefault()
        setLoading(true);

        try {
            const addVendorRes = await axios.post(
                `/business/technologies/addTechnology`,
                addTechnologyFormData
            );

            const vendorListRes = await axios.get("/business/technologies/technologiesList");
            const cyberSecurityRes = await axios.get("/business/cyberSecurity/cyberSecurityTopics");
            dispatch(setCyberSecurityTopics(cyberSecurityRes.data))
            dispatch(setTechnologiesList(formatTechnologiesListWithTopics(vendorListRes.data.vendors, cyberSecurityRes.data)))
            setLoading(false);
            setAddTechnologyFormData({ name: "" })
            dispatch(setPopUpAlert({ message: "Success! Technology added.", type: "success", length: 2000 }))

            //add new vendor to the vendor List accordion state 

            let vendorAdded = addVendorRes.data.vendors.find((vendor) => {
                return vendor.vendor.name === addTechnologyFormData.name
            })

            setTechnologiesListAccordionsState(prev => new Map([...prev, [vendorAdded.vendor.vendor_id, { technologiesListAccordionToggle: false, associateTopicsAccordionToggle: false, securityTopicsAccordionToggle: [] }]]))
        } catch (error) {
            dispatch(setPopUpAlert({ message: getErrorMessage(error), type: "error", length: 5000 }))
            setLoading(false);
        }
    };

    //############################ Edit Technology ############################

    const handleEditClick = (e, showModal, data) => {
        e.stopPropagation()
        setShowEditModal(showModal)
        setSelectedTechnologyItemData(data)
    }

    const closeModalResetData = () => {
        setSelectedTechnologyItemData(null)
        setShowEditModal(false)
        setShowDeleteModal(false)
    }
    //############################ Delete Technology ############################


    const handleDeleteClick = (e, showModal, data) => {
        e.stopPropagation()
        setShowDeleteModal(showModal)
        setSelectedTechnologyItemData(data)
    }

    //########################## Technology Topic association ################################




    if (pageLoading) {
        return (
            <Widget title="Technologies">
                <Loading />
            </Widget>
        )
    }
    return (
        <>
            {showAction && <UnassociateTechnologyTopicModal formatTechnologiesListWithTopics={formatTechnologiesListWithTopics} />}
            {showDeleteModal && <DeleteTechnology formatTechnologiesListWithTopics={formatTechnologiesListWithTopics} closeModalResetData={closeModalResetData} selectedTechnologyItemData={selectedTechnologyItemData} setTechnologiesListAccordionsState={setTechnologiesListAccordionsState} />}
            {showEditModal && <EditTechnology formatTechnologiesListWithTopics={formatTechnologiesListWithTopics} closeModalResetData={closeModalResetData} selectedTechnologyItemData={selectedTechnologyItemData} />}
            <Widget title="Technologies">

                <AddTechnology loading={loading} formatTechnologiesListWithTopics={formatTechnologiesListWithTopics} formSubmit={handleAddFormSubmit} />

                <TechnologiesList handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} handleTechnologiesAccordionToggle={handleTechnologiesAccordionToggle} technologiesListAccordionsState={technologiesListAccordionsState} handleAssociateTopicsAccordionToggle={handleAssociateTopicsAccordionToggle} handleSecurityTopicAccordionToggle={handleSecurityTopicAccordionToggle} />
            </Widget>
        </>
    );
};

export default Technologies;

