import { createSlice } from "@reduxjs/toolkit";

export const SidebarSlice = createSlice({
    name: "sidebar",
    initialState: {
        isSidebarOpen: window.innerWidth <= 1000 ? false : true,
    },
    reducers: {
        toggleSidebarOpen: (state) => {
            state.isSidebarOpen = !state.isSidebarOpen
        },
        setSidebarOpen: (state, action) => {
            state.isSidebarOpen = action.payload
        }
    }
})

export const { toggleSidebarOpen, setSidebarOpen } = SidebarSlice.actions

export default SidebarSlice.reducer