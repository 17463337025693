
import React, { useEffect, useState, useMemo } from "react";
import DiscoveredTechnologiesList from "./discoveredTechnologiesList/discoveredTechnologiesList";
import Domains from "./domains/Domains"
import Button from "../../components/UI/button/Button";
import Widget from "../../components/UI/widget/Widget";
import Loading from "../../components/UI/loading/Loading";
import imageUrl from "../../util/functions/imageUrl";
import moment from 'moment'
import { getErrorMessage } from "../../util/functions/getErrorMessage";
import { useSelector, useDispatch } from "react-redux";
import { reset } from "../../redux/slices/tableSlice";
import { setPopUpAlert } from "../../redux/slices/alertSlice"
import axios from "axios";

import "./technologyDiscovery.css"

const TechnologyDiscovery = () => {
    const [discoveredTechnologiesList, setDiscoveredTechnologiesList] = useState([])
    const [isTechnologyDiscoveryInProgress, setIsTechnologyDiscoveryInProgress] = useState(false)
    const [isTechnologyDiscoveryButtonDisabled, setIsTechnologyDiscoveryButtonDisabled] = useState(false)
    const [nextAvailableDiscoveryDateTime, setNextAvailableDiscoveryDateTime] = useState("")
    const [isDiscoveryAllowed, setIsDiscoveryAllowed] = useState(true)
    const [pageLoading, setPageLoading] = useState(true);
    const [domainsList, setDomainsList] = useState([])
    const [hasFetchedInitialList, setHasFetchedInitialList] = useState(false);
    const [hasScannedPreviously, setHasScannedPreviously] = useState(false);

    const dispatch = useDispatch()

    const handleLocalDatetime = (data) => {
        const dt = moment.utc(data);
        const time = dt.local().format("h:mm A");
        const date = dt.local().format("M/D/YYYY");

        return `${date} ${time}`
    }

    const getDiscoveryEntitySummary = async () => {
        // this endpoint returns a domains_list, in_progress_scan_id, last_completed_scan_id, and last_completed_scan_dt

        try {
            let { data } = await axios.get("/business/technologies/discoveryEntitySummary");

            !hasScannedPreviously && data.discovery.last_complete_scan_id && setHasScannedPreviously(true)

            if (data.discovery.in_progress_scan_id) {
                !isTechnologyDiscoveryButtonDisabled && setIsTechnologyDiscoveryButtonDisabled(true)
                !isTechnologyDiscoveryInProgress && setIsTechnologyDiscoveryInProgress(true)

                if (!hasFetchedInitialList) {
                    getDiscoveredTechnologiesList();
                    setHasFetchedInitialList(true);
                }
            } else {
                setIsTechnologyDiscoveryInProgress(false)

                if (data.discovery_meta.is_discovery_allowed === true) {
                    data.discovery_meta.next_available_discovery_date !== nextAvailableDiscoveryDateTime && setNextAvailableDiscoveryDateTime(data.discovery_meta.next_available_discovery_date)
                    isTechnologyDiscoveryButtonDisabled && setIsTechnologyDiscoveryButtonDisabled(false)
                    !isDiscoveryAllowed && setIsDiscoveryAllowed(true)
                } else {
                    data.discovery_meta.next_available_discovery_date !== nextAvailableDiscoveryDateTime && setNextAvailableDiscoveryDateTime(data.discovery_meta.next_available_discovery_date)
                    !isTechnologyDiscoveryButtonDisabled && setIsTechnologyDiscoveryButtonDisabled(true)
                    isDiscoveryAllowed && setIsDiscoveryAllowed(false)
                }
            }
            if (data.domains) {
                //set domainList state if there is one or more domains and the domainsList state length does not match the response domains list length 
                if (data.domains.length > 0 && domainsList.length !== data.domains.length) {
                    setDomainsList(data.domains)
                }
            }
            setPageLoading(false)

        } catch (error) {
            setIsTechnologyDiscoveryInProgress(false)
            setIsTechnologyDiscoveryButtonDisabled(false)
            setPageLoading(false);
            dispatch(setPopUpAlert({ message: getErrorMessage(error), type: "error", length: 5000 }))
        }

    }


    const getDiscoveredTechnologiesList = async () => {
        try {
            let { data } = await axios.get("/business/technologies/discoveryResults");

            let formattedDiscoveryResults = data.discovery_results.map(el => {
                el = el.discovery_result

                el.detail.forEach(detail => {

                    detail.last_detected_dt = handleLocalDatetime(detail.last_detected_dt)
                    detail.first_detected_dt = handleLocalDatetime(detail.first_detected_dt)
                })
                return el
            })

            setDiscoveredTechnologiesList(formattedDiscoveryResults)
            pageLoading && setPageLoading(false);

        } catch (error) {
            setIsTechnologyDiscoveryInProgress(false)
            setIsTechnologyDiscoveryButtonDisabled(false)
            dispatch(setPopUpAlert({ message: getErrorMessage(error), type: "error", length: 5000 }))
            setPageLoading(false);
        }
    }

    const handleDiscoverTechnologies = async () => {
        setIsTechnologyDiscoveryButtonDisabled(true)

        try {
            let res = await axios.post("/business/technologies/discoverTechnologies");
            if (res.data.discovery_scan_state === "init") {
                setIsTechnologyDiscoveryInProgress(true)
            }
        } catch (error) {
            setIsTechnologyDiscoveryButtonDisabled(false)
            dispatch(setPopUpAlert({ message: getErrorMessage(error), type: "error", length: 5000 }))

        }
    }



    useEffect(() => {
        getDiscoveryEntitySummary()
        dispatch(reset())
    }, [])

    useEffect(() => {
        // get technology discovery results 
        if (nextAvailableDiscoveryDateTime) {
            getDiscoveredTechnologiesList()
        }
    }, [nextAvailableDiscoveryDateTime])


    useEffect(() => {
        let interval
        if (isTechnologyDiscoveryInProgress) {
            interval = setInterval(() => {
                getDiscoveryEntitySummary()
            }, 3000);

        } else {
            clearInterval(interval)
        }

        return () => clearInterval(interval);
    }, [isTechnologyDiscoveryInProgress])




    const handleDiscoveryButtonText = () => {
        if (isTechnologyDiscoveryInProgress) {
            return "Discovering..."
        } else {
            return "Discover Technologies"
        }
    }

    //  dicoveredTechnologies component will only be rerendered if discoveredTechnologiesList state changes
    const listItems = useMemo(() => discoveredTechnologiesList, [discoveredTechnologiesList])

    if (pageLoading) {
        return (
            <Widget title="Technology Discovery">
                <Loading />
            </Widget>
        )
    }

    return (
        <Widget title="Technology Discovery">
            <div className="discover-technologies-container">
                <div>
                    <h4> Technology Discovery will provide you with a list of technologies that you can add to your technologies list.
                        We use domains that you provide us with to perform this scan. Once you have added one or more domains please click the discover button to discover technologies.</h4>
                    <div style={{ display: "flex", alignItems: "center" }}>
                    </div>
                    <Domains getDiscoveryEntitySummary={getDiscoveryEntitySummary} domainsList={domainsList} setDomainsList={setDomainsList} />
                    {hasScannedPreviously &&
                        <div className="discover-action-container">
                            <div className="discover-action-button-icon">
                                <Button disabled={isTechnologyDiscoveryButtonDisabled || domainsList.length < 1} type="primary-dark" text={handleDiscoveryButtonText()} size="lg" style={{ margin: "15px 5px 15px 0px" }} onClick={handleDiscoverTechnologies} />
                                {isTechnologyDiscoveryInProgress &&

                                    <img style={{ height: "40px", marginLeft: "10px" }} src={imageUrl("i/discover-globe.gif")} alt="loading..." />
                                }
                            </div>
                            {!isDiscoveryAllowed && <span style={{ marginLeft: "10px" }}><b>Next available discovery date :</b>&nbsp;{nextAvailableDiscoveryDateTime ? handleLocalDatetime(nextAvailableDiscoveryDateTime) : "No date exists"}</span>}
                        </div>
                    }
                </div>
            </div>
            <DiscoveredTechnologiesList discoveredTechnologiesList={listItems} setDiscoveredTechnologiesList={setDiscoveredTechnologiesList} />
            {!hasScannedPreviously &&
                <div className="discover-action-container-first-discovery">
                    <div className="discover-action-button-icon">
                        <Button disabled={isTechnologyDiscoveryButtonDisabled || domainsList.length < 1} type="primary-dark" text={handleDiscoveryButtonText()} size={"lg"} onClick={handleDiscoverTechnologies} />
                        {isTechnologyDiscoveryInProgress &&
                            <img style={{ height: "50px", marginLeft: "10px" }} src={imageUrl("i/discover-globe.gif")} alt="loading..." />
                        }
                    </div>

                </div>
            }

        </Widget>
    );
};

export default TechnologyDiscovery;