import React, { useState, useMemo } from "react";
// import {
//     Form,
//     Input,
//     Button
// } from "antd";
// import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import baseOC from "icfrontendbase";
import { login } from "../../redux/slices/userSlice";
import env from "../../util/functions/env";
import "./sign.css";

const Sign = props => {
    const dispatch = useDispatch();
    const {
        title,
        type,
        formComplete,
        errorMessage,
        loading,
        email,
        subtitle
    } = props;
    const [emailValue, setEmailValue] = useState("");
    const [emailError, setEmailError] = useState("");
    const handleEmailChange = (e) => {
        const newEmailValue = e.target.value;
        setEmailValue(newEmailValue);
        // email format
        const emailPattern = /^[a-zA-Z0-9]+(?:[._+-][a-zA-Z0-9]+)*@(?!-)[a-zA-Z0-9-]+(?:[.][a-zA-Z0-9-]+)*(?<!-)(?:\.[a-zA-Z]{2,})$/;
        if (!emailPattern.test(newEmailValue)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    };
    let buttonText;
    let oppositeTitle;
    let oppositePage;
    if (title === "Sign Up") {
        buttonText = "Sign Up";
        oppositeTitle = "Login";
        oppositePage = "/login";
    } else if (title === "Login") {
        buttonText = "Login";
        oppositeTitle = "Sign Up";
        oppositePage = "/signup";
    } else if (title === "Confirm Sign Up") {
        buttonText = "Confirm";
    } else if (title === "Forgot Password") {
        buttonText = "Send";
    } else if (title === "Reset Password") {
        buttonText = "Submit";
    }

    const cisobotLogoUrl = useMemo(() => {
        const imageUrlProcurer = baseOC.get("ImageUrlProcurer")
        return imageUrlProcurer.procure("cb_logo");
    }, [])

    return (
        <div
            className="sign-background"
        >
            <div className="sign-outer-container">
                <div className="sign-inner-container">
                    <div className="sign-header">
                        <div>
                            <h1>{title}</h1>
                            {/* <p style={{ maxWidth: "200px" }}>{subtitle}</p> */}
                        </div>       
                        {oppositePage ? (
                            <div style={{ marginTop: "15px" }}>
                                <a href={`${env("MAIN_WEBSITE_URL")}/signup/account/`}>
                                Sign Up
                            {/* <IntlMessages id="app.userAuth.auditorSignUp" /> */}
                            </a>
                            {/* <Link to={oppositePage}>{oppositeTitle}</Link> */}
                            <div style={{ alignContent: "left", marginTop:"10px" }}>
                            <a href="/auditor/signup">
                                Auditor Sign Up
                            {/* <IntlMessages id="app.userAuth.auditorSignUp" /> */}
                            </a>
                            </div>
                            <div style={{ alignContent: "left", marginTop:"10px" }}>
                                <Link to="/reset_password">
                                    Forgot Password
                                </Link>
                            </div>
                            </div>
                        ) : null}                 
                    </div>
                    <div className="sign-body">
                        <div className="sign-logo-container">
                            <img src={cisobotLogoUrl} alt="CISOBot" />
                        </div>
                        {type === "signup_success" ? (
                            <div className="sign-signup-success">
                                <h2>Thank you!</h2>
                                <p>
                                    We are sending you a verification email at
                                    <br/><strong>{email}</strong>.
                                    Please follow the link in the email to complete your sign up.
                                </p>
                            </div>
                        ) : type === "signup_confirm_success-login_true" ? (
                            <div className="sign-signup-success">
                                <h2>Thank you!</h2>
                                <p>
                                    Your account is ready to use.
                                </p>
                                <button
                                    className="login-form-button"
                                    style={{ marginTop: "15px" }}
                                    onClick={() => dispatch(login())}
                                >
                                    Continue to Dashboard
                                </button>
                            </div>
                        ) : type === "signup_confirm_success-login_false" ? (
                            <div className="sign-signup-success">
                                <h2>Thank you!</h2>
                                <p>
                                    Your account is ready to use.
                                </p>
                                <Link to="/login">
                                    <button
                                        className="login-form-button"
                                        style={{ marginTop: "15px" }}
                                    >
                                        Continue to Login
                                    </button>
                                </Link>
                            </div>
                        ) : type === "forgotpassword_sent" ? (
                            <div className="reset-password-content">
                                <p>
                                If your email id is associated with us, an email will be sent to you shortly with instructions on how to reset the password.
                                </p>
                             </div>
                        ) : (
                            <>
                                <form
                                    onSubmit={formComplete}
                                >
                                    {type === "forgotpassword" ? (
                                                <div className="reset-password-content">
                                                    <h2>Forgot Your Password ?</h2>
                                                    <p>
                                                    No worries, first lets verify your email. Then you should receive a password reset link in your inbox.
                                                    </p>
                                                 </div>
                                     ): null}
                                    {type != "resetpassword"?(
                                        <div>
                                        <div className="input-icon-wrapper">
                                            <i className="fas fa-user" />
                                            <input
                                                type="email"
                                                placeholder="Email"
                                                onChange={handleEmailChange}
                                                disabled={loading}
                                                required
                                            />
                                        </div>
                                        <div>
                                        {emailError && <p style={{ color: "#FF4D4F", marginBottom: "15px",marginTop: "-28px" }}>{emailError}</p>}
                                        </div>
                                        </div>
                                    ) : null}
                                    {type != "forgotpassword" || type === "resetpassword" ? (
                                    <div className="input-icon-wrapper">
                                        <i className="fas fa-lock" />
                                        <input
                                            type="password"
                                            placeholder="Password"
                                            disabled={loading}
                                            required
                                        />
                                    </div>
                                    ) : null}
                                    {type === "signup" || type === "resetpassword" ? (
                                        <div className="input-icon-wrapper">
                                            <i className="fas fa-lock" />
                                            <input
                                                type="password"
                                                placeholder="Retype Password"
                                                disabled={loading}
                                                required
                                            />
                                        </div>
                                    ) : null}
                                    <button
                                        type="submit"
                                        className={loading ? "login-form-button button-loading" : "login-form-button"}
                                        disabled={loading}
                                    >
                                        {loading ? <i className="fas fa-spinner button-spinner" /> : null}
                                        {buttonText}
                                    </button>
                                </form>
                                <p style={{ color: "#FF4D4F", marginTop: "10px" }}>{errorMessage}</p>                                
                            </>

                        )}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sign;
