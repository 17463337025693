import React, { useLayoutEffect, useState } from "react";
import Button from "../../../../components/UI/button/Button";
import { handleStatusColor } from "../../../../util/functions/handleStatusColor";
import { chartColorList } from "../../../../util/functions/chartColorList";
import { handleCriticality } from "../../../../util/functions/handleCriticality";
import { statusCodeToName } from "../../../../util/functions/statusCodeToName";
import { useSelector } from "react-redux";
import "./tree.css";

const Tree = ({ cyberSecurityTopics, handleTopicSelected }) => {
    const [treeData, setTreeData] = useState([]);
    const [parentIds, setParentIds] = useState([]);

    // const cyberSecurityTopics = useSelector((state) => state.cyberSecurityTopics.cyberSecurityTopics);

    useLayoutEffect(() => {
        if (cyberSecurityTopics) {
            const newTreeData = createTreeData({ defaultOpen: true });
            setTreeData(newTreeData);

            const newParentIds = createParentIds();
            setParentIds(newParentIds);
        }
    }, [cyberSecurityTopics]);

    const createParentIds = () => {
        const getNestedParentIds = topics => {
            let newParentIds = []
            topics.forEach(topic => {
                if (topic.sub_topics.length > 0) {
                    newParentIds = newParentIds.concat(
                        getNestedParentIds(topic.sub_topics)
                    );
                    newParentIds.push(topic.cybersecurity_topic_id);
                }
            })
            return newParentIds;
        }
        const topicList = cyberSecurityTopics.cybersecurity_topic_summary;
        return getNestedParentIds(topicList);
    }

    const createTreeData = ({ defaultOpen = false }) => {
        const formatNestedData = (topics, colors, depth = 0) => {
            depth++;
            return topics.map((topic, key) => {

                let color = colors;
                // root level topics will have an array of colors.
                // The typeof array is "object"
                if (typeof colors === "object") {
                    color = colors[key]
                }
                return {
                    children: formatNestedData(topic.sub_topics, color, depth),
                    code: topic.code,
                    color: color,
                    cybersecurity_topic_id: topic.cybersecurity_topic_id,
                    defaultOpen: defaultOpen,
                    depth: depth,
                    name: topic.name,
                    priority: topic.priority,
                    scalePriority: topic.scalePriority,
                    status: topic.cybersecurity_topic_status
                }
            })
        }
        const topicList = cyberSecurityTopics.cybersecurity_topic_summary;
        const colorList = chartColorList(topicList.length);
        return formatNestedData(topicList, colorList);
    }

    const handleArrowClick = item => {
        // toggle showing the child items
        let childrenEl = document.getElementById(item.cybersecurity_topic_id + "-children");
        childrenEl.classList.toggle("tree-hidden");

        // toggle the arrow direction
        let arrowEl = document.getElementById(item.cybersecurity_topic_id + "-arrow");
        arrowEl.classList.toggle("fa-chevron-right");
        arrowEl.classList.toggle("fa-chevron-down");
    }

    const handleCloseAll = () => {
        parentIds.forEach(parentId => {
            let childrenEl = document.getElementById(parentId + "-children");
            if (childrenEl.classList.contains("tree-hidden") === false) {
                childrenEl.classList.add("tree-hidden")
            }
            let arrowEl = document.getElementById(parentId + "-arrow");
            if (arrowEl.classList.contains("fa-chevron-right") === false) {
                arrowEl.classList.add("fa-chevron-right")
            }
            if (arrowEl.classList.contains("fa-chevron-down")) {
                arrowEl.classList.remove("fa-chevron-down")
            }
        })
    }

    const handleOpenAll = () => {
        parentIds.forEach(parentId => {
            let childrenEl = document.getElementById(parentId + "-children");
            if (childrenEl.classList.contains("tree-hidden")) {
                childrenEl.classList.remove("tree-hidden")
            }
            let arrowEl = document.getElementById(parentId + "-arrow");
            if (arrowEl.classList.contains("fa-chevron-right")) {
                arrowEl.classList.remove("fa-chevron-right")
            }
            if (arrowEl.classList.contains("fa-chevron-down") === false) {
                arrowEl.classList.add("fa-chevron-down")
            }
        })
    }

    const displayTree = (items, depth = 1) => {
        return (
            <>
                {items.map((item, key) => {

                    let arrowButton = null;
                    const criticality = handleCriticality(item?.scalePriority);
                    let status = item.status.state.replace("_", " ");
                    let formattedStatus = status.charAt(0).toUpperCase() + status.slice(1);
                    let opacity = 0.9;

                    if (item.children.length > 0) {
                        arrowButton = (
                            <button
                                onClick={() => handleArrowClick(item)}
                                className="tree-item-arrow-button"
                            >
                                <i
                                    id={item.cybersecurity_topic_id + "-arrow"}
                                    className={item.defaultOpen ? "fas fa-chevron-down" : "fas fa-chevron-right"}
                                />
                            </button>
                        )
                        opacity = 1;
                    }

                    return (
                        <div className="tree-item-container" key={key}>
                            <div className="tree-item" style={{ backgroundColor: item.color, opacity }}>
                                <div className="tree-item-left">
                                    {arrowButton}
                                    {criticality?.icon ? (
                                        <img
                                            src={criticality?.icon}
                                            className="tree-item-priority"
                                            alt={criticality?.criticality}
                                            title={criticality?.criticality + " Priority"}
                                        />
                                    ) : null}
                                    <p className="tree-item-name">
                                        {item.name}
                                        {item.children.length > 0 ? (
                                            <>
                                                <span className="tree-item-name-parenthesis">&nbsp;(</span>
                                                {item.children.length}
                                                <span className="tree-item-name-parenthesis">)</span>
                                            </>
                                        ) : null}
                                    </p>
                                </div>
                                <div className="tree-item-right">
                                    <p className="tree-item-status">
                                        {item.status.state !== "default" ? (
                                            <i
                                                className="fas fa-circle"
                                                title={statusCodeToName(item.status.state)}
                                                style={{ color: handleStatusColor(item.status.state) }}
                                            />
                                        ) : null}
                                    </p>
                                    <button
                                        className="tree-item-edit"
                                        onClick={() => {
                                            handleTopicSelected({
                                                name: item.name,
                                                cybersecurity_topic_id: item.cybersecurity_topic_id,
                                                cybersecurity_topic_status: item.status
                                            });
                                        }}
                                    >
                                        Edit
                                    </button>
                                </div>
                                <span className="tooltiptext">
                                    <p>
                                        <b>{item.name}</b><br />
                                        <b>Status : </b>{formattedStatus}<br />
                                        {criticality?.criticality && (
                                            <><b>Priority : </b>{criticality?.criticality}</>
                                        )}
                                    </p>
                                </span>
                            </div>
                            <div id={item.cybersecurity_topic_id + "-children"} className={item.defaultOpen ? "" : "tree-hidden"}>
                                {displayTree(item.children, depth++)}
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }

    return (
        <div className="tree-container">
            <div className="tree-control-buttons">
                <Button
                    type="default"
                    text="Collapse"
                    onClick={handleCloseAll}
                />
                <Button
                    type="default"
                    text="Expand"
                    onClick={handleOpenAll}
                />
            </div>
            <br />
            {displayTree(treeData)}
        </div>
    )
}

export default Tree;