import React, { useEffect, useState, useMemo,useRef } from "react";
import Table from "../../../components/UI/Table/Table";
import "./discoveredTechnologiesList.css"
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Button from "../../../components/UI/button/Button";
import { getErrorMessage } from "../../../util/functions/getErrorMessage";
import { handleLocalDatetime } from "../../../util/functions/handleLocalDateTime";
import axios from "axios";

import { setPopUpAlert } from "../../../redux/slices/alertSlice"

const DiscoveredTechnologiesList = ({ discoveredTechnologiesList,  setDiscoveredTechnologiesList}) => {
    const dispatch = useDispatch()
    // discovered technologies columns

    const columns = [
        {
            value: "name",
            header: "Technology Name",
        },
        {
            value: "description",
            header: "Description",
            format: function (item, value, rowData) {

                if (value.length > 1000) {
                    return (
                        <span> {value.substring(0, 1000) + '... '}</span>

                    )
                } else {
                    return (
                        <span>{value}</span>
                    )

                }
            }
        },
        {
            value: "is_in_vendors_list",
            header: "Add To My List",
            format: function (item, value, data) {
                return (
                    <div >
                        { data.is_in_vendor_list ?                          
                            <Button style={{
                                fontSize: "12px", width: "60px", borderColor: "dodgerblue",
                                color: "dodgerblue", cursor: "unset", margin:"0px 12px"
                            }} type="default" text="Added" />                        
                            :
                            <Button id={`button-${data.discovery_result_id}`} style={{
                                fontSize: "12px", width: "60px",margin:"0px 12px"
                            }} type="primary" onClick={(e) => handleAddDiscoveredTechnology(e,data)} text={handleAddTechnologyButtonText()} />                       
                        }
                    </div>
                )
            }
        },
    ]
    


    //collapsible discovered technologies columns
    const collapsibleColumns = [
        {
            value: "detail",
            header: "Details",
            format: function (item, value, rowData, index) {
                if (value && value.length > 0) {
                    return (
                        <tr key={`collapsible-${index}`}>
                            <th><span>{item.header}:</span></th>
                            <td>
                                <Table
                                    name="technology-discovery-details-table"
                                    noDataBody="There are currently no discovered technologies."
                                    noDataHeader="Discovered Technologies List"
                                    data={value}
                                    columns={technologyDetailsColumns}
                                    style={{ fontSize: "12px", marginTop: "10px", width: "100%" }}
                                    collapsible={true}
                                    collapsibleColumns={collapsibleTechnologyDetailsColumns}
                                    colSpan="4"
                                >
                                </Table>
                            </td>
                        </tr>
                    )
                } else{
                    return <span>N/A</span>
                }
            }
        },
        {
            value: "description",
            header: "Description",
            format: function (item, value, rowData, index) {
                return (
                    <tr key={`collapsible-${index}`}>
                        <th><span>{item.header}:</span></th>

                        <td>
                            {value.length > 1000 ? <span> {value.substring(0, 1000) + '... '}</span> : <span>{value}</span>}
                        </td>

                    </tr>
                )
            }
        },
        {
            header: "Parent",
            value: "parent",
        },

        {
            header: "Categories",
            value: "categories",
        },
        {
            header: "Link",
            value: "link",
        },
        {
            header: "Tag",
            value: "tags",
        },
    ]


    // each discovered technology has a  details array of dictionarys. This will be a another table these are the details columns
    const technologyDetailsColumns = [
        {
            header: "Domain",
            value: "domain",
        },
        {
            header: "Sub Domain",
            value: "subdomain",
        },
        {
            header: "Url",
            value: "url",
        },
        {
            header: "Is Premium",
            value: "is_premium",
        },
        {
            header: "First Detected",
            value: "first_detected_dt",
        },
        {
            header: "Last Detected",
            value: "last_detected_dt",
        },
    ]

    // collapsible details for smaller screen width
    const collapsibleTechnologyDetailsColumns = [

        {
            header: "Url",
            value: "url",
        },
        {
            header: "Is Premium",
            value: "is_premium",
        },
        {
            header: "First Detected",
            value: "first_detected_dt",
        },
        {
            header: "Last Detected",
            value: "last_detected_dt",
        },
    ]


    const handleAddTechnologyButtonText = () => {
        return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FontAwesomeIcon icon={faPlus} />
                <span >Add</span>
            </div>
        )
    }

    const handleAddDiscoveredTechnology = async (e,data) => {
     
        document.getElementById(`button-${data.discovery_result_id}`).disabled = true;
        try {
            const addTechnologyRes = await axios.post(
                `/business/technologies/addTechnologyToList`,
                {
                    name: data.name
                }
                );
                             
            let formattedDiscoveryResults = addTechnologyRes.data.discovery_results.map(el => {
                el = el.discovery_result

                el.detail.forEach(detail => {

                    detail.last_detected_dt = handleLocalDatetime(detail.last_detected_dt)
                    detail.first_detected_dt = handleLocalDatetime(detail.first_detected_dt)
                })
                return el
            })

            setDiscoveredTechnologiesList(formattedDiscoveryResults)
            dispatch(setPopUpAlert({ message: "Success! technology added.", type: "success", length: 2000 }))
         
        
        } catch (error) {
            dispatch(setPopUpAlert({ message: getErrorMessage(error), type: "error", length: 5000 }))
            document.getElementById(`button-${data.discovery_result_id}`).disabled = false;
           
        }
    };

    return (

        <div className="dt-list">
            <div>
                <Table
                    name="technology-discovery-table"
                    noDataBody="There are currently no discovered technologies."
                    noDataHeader="Discovered Technologies"
                    data={discoveredTechnologiesList}
                    columns={columns}
                    style={{ fontSize: "13px", marginTop: "10px", width: "100%" }}
                    collapsible={true}
                    collapsibleColumns={collapsibleColumns}
                    colSpan="4"
                    initialSort="name"
                >
                </Table>

            </div>

        </div>

    );
};

export default React.memo(DiscoveredTechnologiesList);