import React from "react";
import "./Input.css";
const Input = (props) => {

    return (
        <div>
            <div
                className="control"
            >
                {props.label &&
                    <label className={`${props.required === true ? "required" : ""
                        }`} htmlFor={props.id}>{props.label}:</label>
                }
                <input
                    required={props.required}
                    style={props.style}
                    type={props.type}
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                />
            </div>
        </div>
    );
};

export default Input;