import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import store from "./redux/store";
import RequestInterceptor from "./util/RequestInterceptor";
import "./styles/main.css";
import "icfrontendui/ui/styled/main.css";

let persistor = persistStore(store);

const container = document.getElementById("root");
const root = createRoot(container);

RequestInterceptor.setup(store);
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
    // </React.StrictMode>
);