import React, { useEffect, useRef, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { handleStatusColor } from "../../../../util/functions/handleStatusColor";
import { handleCriticality } from "../../../../util/functions/handleCriticality";

const Bubble = ({ cyberSecurityTopics, handleTopicSelected }) => {
    const [ chartHeight, setChartHeight ] = useState(700);
    const [ topicCount, setTopicCount ] = useState(0);
    const [ topicMinMaxRadius, setTopicMinMaxRadius ] = useState({ min: 30, max: 70 });
    const bubbleChartRef = useRef(null);

    useEffect(() => {
        if (cyberSecurityTopics === null) return;
        const formattedData = formatData(cyberSecurityTopics);
        bubbleChartRef.current.data.setAll([formattedData]);
        bubbleChartRef.current.set(
            "selectedDataItem",
            bubbleChartRef.current.dataItems[0]
        );
    }, [cyberSecurityTopics]);

    const calcChartHeight = ({ curTopicCount=topicCount }) => {
        const chartWidth = document.getElementById("chart-bubble").offsetWidth;
        const approxChartArea = Math.pow((((topicMinMaxRadius.max + topicMinMaxRadius.min) / 2) * 2), 2) * curTopicCount;
        const newChartHeight = Math.round((approxChartArea / chartWidth)) + 300;
        setChartHeight(newChartHeight);
    }

    // Get the bottom child nodes, nodes that have sub_topics are considered parents
    // Use a breadcrumb to show all parent names
    const formatData = (cyberSecurityTopics) => {
        let children = [];
        let newTopicCount = 0;

        cyberSecurityTopics.cybersecurity_topic_summary.forEach((category) => {
            let leafs = [];

            // only add the leaf if there are no subtopics
            const handleAddLeaf = (subtopic, groupName) => {
                if (subtopic.sub_topics.length === 0) {
                    newTopicCount++;
                    leafs.push({
                        name: {
                            original: subtopic.name,
                            formatted: formatNameWithStatus(
                                subtopic.name,
                                subtopic.cybersecurity_topic_status.state
                            ),
                            formattedLabel : formatLabel(
                                subtopic.scalePriority
                              ),
                            formattedState : formatState(
                                subtopic.cybersecurity_topic_status.state
                            ),
                            group: groupName,
                        },
                        value: parseInt(subtopic.priority),
                        cybersecurity_topic_id: subtopic.cybersecurity_topic_id,
                        cybersecurity_topic_status: subtopic.cybersecurity_topic_status,
                        // state: "pass"//child.state
                    });
                }
            };

            const handleNestedAddLeaf = (data, groupName = `${category.name} > `) => {
                data.sub_topics.forEach((subtopic) => {
                    handleAddLeaf(subtopic, groupName);

                    const nextGroupName = groupName + subtopic.name + " > ";
                    handleNestedAddLeaf(subtopic, nextGroupName);
                });
                return data;
            };

            handleAddLeaf(category, "");

            handleNestedAddLeaf(category);

            children.push({
                value: 0,
                name: {
                    original: "",
                    formatted: "",
                    group: "",
                },
                children: leafs,
            });
        });

        setTopicCount(newTopicCount);
        calcChartHeight({ curTopicCount: newTopicCount });

        return {
            value: 0,
            children: children,
        };
    };

    // Returns a shorter and/or multiline string
    // name => string
    const formatName = (name) => {
        let fName = name;
        const ignoreLength = 10; // Any string with this many or less characters will not be formatted
        const maxLength = 20; // The max length of a string before showing an elipsis
        const elipsisIndex = maxLength - 2; // where the elipsis will be placed in the string

        if (fName.length < ignoreLength) return fName;

        // if name longer than max length
        // then shorten the name and add elipsis
        if (name.length > maxLength) {
            fName = fName.slice(0, elipsisIndex);
            fName += "...";
        }

        // find the space closest to the middle of the string
        // add a line break at that index, and remove the space
        const middleIndex = Math.round(fName.length / 2);
        let spaceIndex = null;
        for (let i = 0; i < fName.length; i++) {
            if (fName[i] !== " ") continue;

            if (spaceIndex === null) {
                spaceIndex = i;
                continue;
            }

            let spaceIndexDiff = Math.abs(middleIndex - spaceIndex);
            let curIndexDiff = Math.abs(middleIndex - i);
            if (curIndexDiff < spaceIndexDiff) {
                spaceIndex = i;
            }
        }
        if (spaceIndex === null) return fName;
        fName = fName.slice(0, spaceIndex) + "\n" + fName.slice(spaceIndex + 1);

        return fName;
    };

    const formatState = (state)=>{
        let status = state?.replace("_", " ");
        let statusValue = status.charAt(0).toUpperCase()+ status.slice(1);
        return statusValue;
      }
    
      const formatLabel = (scalePriority)=>{
        let priorityValue = handleCriticality(scalePriority)?.criticality;
        return priorityValue;
      }

    const formatNameWithStatus = (name, status) => {
        let formattedStatus = status.replace("_", " ");
        let fName;

        if (status === "default") {
            fName = `[bold]${formatName(name)} `;
        } else {
            //   fName = `[bold]${formatName(name)}[/] \n ([${handleStatusColor(
            //     status
            //   )}]● [/]${formattedStatus})`;

            fName = `[bold]${formatName(name)} [${handleStatusColor(status)}]● [/]`;
        }
        return fName;
    };

    useLayoutEffect(() => {
        let root = am5.Root.new("chart-bubble");

        root.setThemes([am5themes_Animated.new(root)]);

        let container = root.container.children.push(
            am5.Container.new(root, {
                width: am5.percent(100),
                height: am5.percent(100),
                layout: root.verticalLayout,
            })
        );

        let series = container.children.push(
            am5hierarchy.ForceDirected.new(root, {
                singleBranchOnly: false,
                downDepth: 2,
                topDepth: 2,
                initialDepth: 1,
                maxRadius: topicMinMaxRadius.max,
                minRadius: topicMinMaxRadius.min,
                valueField: "value",
                categoryField: "name",
                childDataField: "children",
                manyBodyStrength: -3,
                centerStrength: 0.8,
                velocityDecay: .5
            })
        );

        series.circles.template.adapters.add("fill", function (fill, target) {
            if (target?._dataItem?.dataContext?.name?.original) {
                if (target._dataItem.dataContext.name.original === "") {
                    return am5.color(0xffffff);
                }
                return fill;
            }
        });

        // series.nodes.template.adapters.add("tooltipText", function (fill, target) {
        //     fill: am5.color(0x6a6a6a)
        //     if (target?._dataItem?.dataContext?.name?.original) {
        //         if (target._dataItem.dataContext.name.original === "") {
        //             return fill;
        //         }
        //         return fill;
        //     }
        // });

        // series.circles.template.adapters.add("stroke", function(fill, target) {
        //     if (target._dataItem.dataContext.state) {
        //         if (target._dataItem.dataContext.state === "pass") {
        //             return am5.color(0x0bb900);
        //         }
        //         else {
        //             return am5.color(0xf40000);
        //         }
        //     }
        // });

        series.circles.template.setAll({
            fillOpacity: 0.5,
            // strokeWidth: 3,
            // strokeOpacity: 1
        });

        series.labels.template.setAll({
            fill: am5.color(0x2a2a2a),
            textAlign: "center",
            breakWord: true,
            text: "{category.formatted}",
        });

        series.nodes.template.setAll({
            // tooltipText: null,
            cursorOverStyle: "pointer",
            draggable: false
        });

        series.outerCircles.template.setAll({
            forceHidden: true,
        });

        let tooltip = am5.Tooltip.new(root, {
            getFillFromSprite: false,
            autoTextColor: false,
            labelText: "[bold]{category.formatted}[/]\n [bold]Status[/] : {category.formattedState}\n [bold]Priority[/] : {category.formattedLabel}",
          });
          
        tooltip.get("background").setAll({
            fill: am5.color(0x6a6a6a),
        });

        tooltip.label.setAll({
            fill: am5.color(0xffffff)
          });
    
        series.set("tooltip", tooltip);

        series.nodes.template.events.on("click", function (e) {
            const dataContext = e.target.dataItem.dataContext;

            handleTopicSelected({
                name: dataContext.name.original,
                cybersecurity_topic_id: dataContext.cybersecurity_topic_id,
                cybersecurity_topic_status: dataContext.cybersecurity_topic_status,
            });
        });

        bubbleChartRef.current = series;

        series.appear(500, 100);

        return () => {
            root.dispose();
        };
    }, []);

    return (
        <>
            <div id="chart-bubble" style={{ height: `${chartHeight}px` }} />
        </>
    );
};

export default Bubble;
