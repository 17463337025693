
import React, { useState, useEffect } from "react";
import Widget from "../../components/UI/widget/Widget";
import VCisoProfilesList from "./vCisoProfilesList/VCisoProfilesList"
import VCisoProfileModal from "./vCisoProfileModal/VCisoProfileModal";
import Loading from "../../components/UI/loading/Loading";
import axios from "axios";
import "./vCisoMarketplace.css"

const VCisoMarketplace = ({ }) => {

    const [vCisoProfiles, setVCisoProfiles] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const [showVCisoProfileModal, setShowVCisoProfileModal] = useState(false);
    const [vCisoProfileSelected, setVCisoProfileSelected] = useState(null);


    const handleProfileClick = (profile) => {
        setVCisoProfileSelected(profile)
        setShowVCisoProfileModal(true)
    }

    const handleProfileModalCancel = () => {
        setVCisoProfileSelected(null)
        setShowVCisoProfileModal(false)
    }



    //############################ Vendor List ############################
    const getVCisoProfilesList = async () => {

        try {
            let res = await axios.get("/business/vCisoMarketplace/vCisoProfiles");

            setVCisoProfiles(res.data.profiles)
            setPageLoading(false);

        } catch (error) {
            setPageLoading(false);
        }
    }

    useEffect(() => {

        getVCisoProfilesList();
    }, []);


    if (pageLoading) {
        return (
            <Widget title="Virtual CISO MarketPlace">
                <Loading />
            </Widget>
        )
    }
    return (
        <>
            {showVCisoProfileModal && <VCisoProfileModal handleProfileModalCancel={handleProfileModalCancel} profile={vCisoProfileSelected} />}
            <Widget title="Virtual CISO MarketPlace">
                {vCisoProfiles[0] ?
                    <VCisoProfilesList profiles={vCisoProfiles && vCisoProfiles} handleProfileClick={handleProfileClick} /> :
                    <div className="vcm-no-data">There are no virtual CISO profiles right now. Please check again later.</div>
                }
            </Widget>
        </>
    );
};

export default VCisoMarketplace;