import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Modal from "../../components/UI/modal/Modal";
import { handleSignout } from "../../util/functions/handleSignout";

let initTimeoutDelay =  60 * 17; // delay in seconds before timeout dialog 
let initTimeoutDuration = 60 * 2; // how long to display dialog in seconds
let timeoutDelay = initTimeoutDelay; 
let timeoutDuration = initTimeoutDuration; 
let timeRemaining = timeoutDelay + timeoutDuration;
let lastDate = new Date();
let currentDate = new Date();

class TimeoutWarning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeRemainingText: "",
            showModal: false
        }
    }

    componentDidMount = () => {
        this.startTimeout();
        clearInterval(this.intervalId)
        this.intervalId = null;
        timeoutDelay = initTimeoutDelay;
        timeoutDuration = initTimeoutDuration;
        timeRemaining = timeoutDelay + timeoutDuration;
        lastDate = new Date();
        currentDate = new Date();
        this.startTimeout();

    }
    componentDidUpdate(oldProps) {
        const { lastRequestUrl } = this.props;
        if(oldProps.lastRequestUrl !== lastRequestUrl && lastRequestUrl !== "/business/account/signout") {
            clearInterval(this.intervalId);
            this.intervalId = null;
            timeoutDelay = initTimeoutDelay;
            timeoutDuration = initTimeoutDuration;
            timeRemaining = timeoutDelay + timeoutDuration;
            lastDate = new Date();
            currentDate = new Date();
            this.startTimeout();
        }
        if(lastRequestUrl === "/business/account/signout") {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }


    extendSession = () => {
        axios.get("/business/account/checkSession");
        clearInterval(this.intervalId);
        this.intervalId = null;
        timeoutDelay = initTimeoutDelay;
        timeoutDuration = initTimeoutDuration;
        timeRemaining = timeoutDelay + timeoutDuration;
        lastDate = new Date();
        currentDate = new Date();
        this.startTimeout();
        this.setState({
            timeRemainingText: "",
            showModal: false
        });
    }

    startTimeout = () => {
        return (
            this.intervalId = setInterval(() => {
                currentDate = new Date();
                timeRemaining = Math.round((timeoutDelay + timeoutDuration) - ((currentDate - lastDate) / 1000));

                if (document.getElementById("timeout-warning-container") === null) {
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                }

                if(timeRemaining === timeoutDuration) {
                    const newTimeRemainingText = this.formatTimeRemaining(timeRemaining);
                    this.setState({
                        timeRemainingText: newTimeRemainingText,
                        showModal: true
                    });
                } else if(timeRemaining < timeoutDuration && timeRemaining > 0) {  
                    if (this.state.showModal === false) {
                        this.setState({ 
                            showModal: true
                        });
                    }
                    const newTimeRemainingText = this.formatTimeRemaining(timeRemaining);
                    this.setState({
                        timeRemainingText: newTimeRemainingText
                    });
                } else if(timeRemaining < 1){
                    clearInterval(this.intervalId);
                    this.intervalId = null;
                    handleSignout();
                }
            }, 1000)
        )
    }

    formatTimeRemaining = timeRemaining => {
        let minutes = Math.floor(timeRemaining / 60);
        if(minutes === 0) minutes = "";
        if(minutes === 1) minutes = minutes + " minute ";
        if(minutes > 1) minutes = minutes + " minutes ";

        let seconds = timeRemaining % 60;
        if (seconds === 0) seconds = "";
        if(seconds === 1) seconds = seconds + " second";
        if(seconds > 1) seconds = seconds + " seconds";

        return minutes + seconds;
    }

    render = () => {
        const { timeRemainingText, showModal } = this.state;

        return (
            <div id="timeout-warning-container" style={{ position: "relative", zIndex: 1000}}>
                {showModal ? (
                    <Modal 
                        title="Session Expiration Warning"
                        onCancel={() => {
                            clearInterval(this.intervalId);
                            this.intervalId = null;
                            handleSignout();
                        }}
                        onConfirm={this.extendSession}
                        loading={false} 
                        confirmDisabled={false}
                        cancelText="Sign Out"
                        submitText="Continue"
                    >
                        <p style={{ margin: "30px 0", fontSize: "16px" }}>
                            Due to inactivity, your session will expire in {timeRemainingText}.<br/>
                            Do you want to extend the session?
                        </p>
                    </Modal>
                 ) : null} 
            </div>
        )
    }
}

const mapStateToProps = ({ request }) => {
    const { lastRequestUrl } = request;
    return { lastRequestUrl }
}

const mapDispatchToProps = dispatch => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeoutWarning);