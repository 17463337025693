import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Tag from "../../../components/UI/tag/Tag";
import Modal from "../../../components/UI/modal/Modal";
import Button from "../../../components/UI/button/Button";
import Alert from "../../../components/UI/alert/Alert"
import Input from "../../../components/UI/Input/Input";
import { setPopUpAlert } from "../../../redux/slices/alertSlice"
import { getErrorMessage } from "../../../util/functions/getErrorMessage";
import { useSelector, useDispatch } from "react-redux";
import "./domains.css"

const initialFormData = {
    domain: '',
};

const Domains = ({ domainsList, setDomainsList}) => {

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [confirmModalData, setConfirmModalData] = useState(null)
    const [deleteAlertMessage, setDeleteAlertMessage] = useState("");
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [addLoading, setAddLoading] = useState(false)
    const [domainFormData, setDomainFormData] = useState(initialFormData)
    const [addDomain, setAddDomain] = useState(false)
    const [validDomain, setValidDomain] = useState(null)


    const dispatch = useDispatch()


    //############################ Add Domain ############################
    const domainValidation = (data) => {
        var re = /^([a-z0-9|-]+\.)*[a-z0-9|-]+\.[a-z]+$/;

        return re.test(data);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDomainFormData(prev => ({ ...prev, [name]: value }))
        if (!value && validDomain === false) {
            setValidDomain(null)
        } else {

            setValidDomain(domainValidation(value))
        }
    }

    const handleAddDomain = async (e) => {
        e.preventDefault()

        setAddLoading(true);

        try {
            const { data } = await axios.post(
                `/business/technologies/addDomain`,
                domainFormData
            );

            if (data.domains) {
                data.domains.length > 0 && setDomainsList(data.domains)
            }

            dispatch(setPopUpAlert({ message: "Success! ", type: "success", length: 2000 }))
            setDomainFormData(initialFormData)
            setAddLoading(false)
            setAddDomain(false)
        } catch (error) {
            if (error) {
                const errorMessage = getErrorMessage(error)
                dispatch(setPopUpAlert({ message: errorMessage, type: "error", length: 7000 }))
                setAddLoading(false)

            }

        }

    };

    //############################ Delete Domain ############################

    const handleRemoveDomain = async (domainBeingRemoved) => {
   
        setDeleteLoading(true)
        try {
            const {data} = await axios.post(
                `/business/technologies/removeDomain`,
                {
                    domain: domainBeingRemoved
                }
            );
        
            setDomainsList(data.domains)
            dispatch(setPopUpAlert({ message: "Success! ", type: "success", length: 2000 }))
            handleCloseConfirmModal()
            setDeleteLoading(false)
        } catch (error) {

            if (error) {
                const errorMessage = getErrorMessage(error)

                if (errorMessage === "Successfully deleted domain. An error occured while getting the list of domains. Please try to refresh the page or try again soon.") {
                    handleCloseConfirmModal()
                    dispatch(setPopUpAlert({ message: errorMessage, type: "error", length: 7000 }))
                } else {

                    setDeleteAlertMessage({ message: errorMessage, type: 'error' })
                }

                setDeleteLoading(false)
            }
        }
    }


    const handleAddDomainClick = () => {
        setAddDomain(true)
    }

    const handleOpenConfirmModal = (data) => {
        setShowConfirmModal(true)
        setConfirmModalData(data)
    }

    const handleCloseConfirmModal = () => {
        setShowConfirmModal(false)
        setConfirmModalData(null)
        setDeleteAlertMessage("")
    }

    return (
        <>
            {showConfirmModal && < Modal confirmDisabled={deleteLoading} loading={deleteLoading} onCancel={handleCloseConfirmModal} onConfirm={()=>handleRemoveDomain(confirmModalData)} title={`Delete Domain`}> Are you sure you want to delete {confirmModalData}
                <Alert type={deleteAlertMessage.type} message={deleteAlertMessage.message} showIcon={true} />
            </Modal>}
            <div className="domains-container">
                <h3>Domains:</h3>

                <div className="dc-tags-container">
                    {domainsList && domainsList.length > 0 && domainsList.map((domain, index) => {
                        return (
                            <div key={index}>
                                <Tag style={{ margin: "5px 2px" }} color="cyan" onClose={() => handleOpenConfirmModal(domain.user_domain.domain)}>{domain.user_domain.domain}</Tag>
                            </div>
                        )
                    })}
                    {!addDomain ?
                        <div onClick={handleAddDomainClick} className="dc-add-tag">+  Add Domain</div> :
                        <form onSubmit={(e) => handleAddDomain(e)}>
                            <div style={{ margin: "5px 2px", display: "flex" }}>
                                <Input type="text" name="domain" required={true}
                                    onChange={(e) => handleChange(e)}
                                    value={domainFormData.domain}
                                />
                                <Button disabled={addLoading || !validDomain } loading={addLoading} style={{ marginLeft: "2px" }} size="md" type="submit-dark" text="Add"></Button>

                                {validDomain === false && <span className='invalid-domain'>Invalid domain format</span>}
                            </div>
                        </form>
                    }
                </div>
            </div>
        </>
    );
};

export default Domains;

