import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { login, primaryRole } from "../../redux/slices/userSlice";
import Sign from "../../components/sign/Sign";
import { getSafe } from "../../util/functions/getSafe";
import baseOC from "icfrontendbase";
import { useSignInClient } from "icfrontendui";

const sharedStorage = baseOC.get("SharedStorage");

const Login = (props) => {
  const { setShowLoading } = props;
  const [sendSignIn] = useSignInClient();

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    const formData = {
      email: e.target[0].value,
      password: e.target[1].value,
    };

    setLoading(true);
    try {
      // this is temporarily using the request class directly to sign in
      // later we should use the login product component
      let resArr = await Promise.all([
        axios.post("/business/account/login", {
          email: formData.email,
          password: formData.password,
        }),
        sendSignIn({
          email_address: formData.email,
          password: formData.password
        })
      ]);
      const legacyRes = resArr[0];
      const libRes = resArr[1];

      if (libRes?.is_error) {
        setLoading(false);
        if (libRes.code === "INVALID_SIGNIN" || libRes.code === "VALIDATION_ERROR") {
          setErrorMessage("Not able to authenticate the user, please check your Email Address and Password")
          return;
        }
        setErrorMessage("We're sorry, there was an error during sign in. Please try again soon")
        return
      }

      dispatch(login());
      dispatch(primaryRole(legacyRes.data.primary_role));
      sharedStorage.set("auth_is_signed_in", true);
      sharedStorage.set("auth_primary_role", legacyRes.data.primary_role);
      // set the default whitelabel
      const imageUrlProcurer = baseOC.get("ImageUrlProcurer");
      sharedStorage.set("whitelabel", {
        name: "CISOBot",
        copyright_name: "CISOBot",
        safe_name: "CISOBot",
        logo_url: imageUrlProcurer.procure("cb_logo"),
        customer_support_contact: {
          email: "customersuccess@cisobot.ai",
        },
        feature_toggle: {
          assessment_check_remediation_link: true
        }
      })
      setShowLoading(true);
      window.location.replace("/comp/");

    } catch (error) {
      setLoading(false);
      const msg = getSafe(() => error.response.data.message, "");
      setErrorMessage(msg);
    }
  };

  return (
    <Sign
      title="Login"
      subtitle="Cloud Infrastructure Security and Compliance Starts Here"
      type="login"
      formComplete={handleLogin}
      errorMessage={errorMessage}
      loading={loading}
    />
  );
};

export default Login;
