import React, { useEffect, useState } from "react";
import "./dashboard.css";
import axios from "axios";
import Widget from "../../components/UI/widget/Widget";
import Loading from "../../components/UI/loading/Loading";
import ChartsWrapper from "./charts/chartsWrapper/ChartsWrapper";
import ChartInteractionDirections from "../dashboard/charts/chartInteractionDirections/ChartInteractionDirections";
import Alert from "../../components/UI/alert/Alert";;

import { getErrorMessage } from "../../util/functions/getErrorMessage";
import { setPopUpAlert } from "../../redux/slices/alertSlice";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTechnologiesList } from "../../redux/slices/technologiesSlice"
import { setCyberSecurityTopics } from "../../redux/slices/cyberSecurityTopicsSlice"
import { formatTechnologiesListWithTopics } from "../../util/functions/formatTechnologiesListWithTopics";
import { topicsWithSubTopics, resetTopicsWithSubTopics } from "../../redux/slices/cyberSecurityTopicsSlice"
const Dashboard = () => {
    const { chartType } = useParams();

    const [allPrioritiesEqual, setAllPrioritiesEqual] = useState(true);
    const [showChart, setShowChart] = useState("sunburst");
    const [topicErrorMessage, setTopicErrorMessage] = useState("");
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch()


    const getCyberSecurityTopicsData = async () => {
        let success = false
        try {
            let res = await axios.get("/business/cyberSecurity/cyberSecurityTopics");

            dispatch(setCyberSecurityTopics(res.data
            ))

            setAllPrioritiesEqual(res.data.all_priorities_equal)
            success = true
            if (success) {
                let technologiesListsRes = await axios.get("/business/technologies/technologiesList");
                dispatch(setTechnologiesList(formatTechnologiesListWithTopics(technologiesListsRes.data.vendors, res.data)))

                dispatch(topicsWithSubTopics(res.data))
            }
        } catch (error) {
            if (success) {
                dispatch(setPopUpAlert({ message: getErrorMessage(error), type: "error", length: 5000 }))
            } else {
                setTopicErrorMessage({ message: getErrorMessage(error), type: 'error' })
            }
        }
        setLoading(false);
    };


    useEffect(() => {
        getCyberSecurityTopicsData();
        setShowChart(chartType)
    }, [chartType]);



    if (loading) {
        return (
            <Loading />
        )
    }
    return (
        <div>
            <Widget title="Dashboard">
                <ChartInteractionDirections chartType={showChart} allPrioritiesEqual={allPrioritiesEqual} />
                {topicErrorMessage ? (
                    <Alert type={topicErrorMessage.type} message={topicErrorMessage.message} showIcon={true} />
                ) : (
                    <div>
                        <ChartsWrapper showChart={showChart} />
                    </div>
                )}
            </Widget>
        </div>
    );
};

export default Dashboard;
