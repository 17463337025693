
import React, { useState } from "react";
import VCisoProfileCard from "../vCisoProfileCard/VCisoProfileCard"
import "./vCisoProfilesList.css"


const VCisoProfilesList = ({ profiles, handleProfileClick }) => {

    return (
        <div className='vciso-profiles-container'>
            {profiles && profiles.map((profile, index) => {
                return (
                    <div key={index}>
                        <VCisoProfileCard profile={profile} handleProfileClick={handleProfileClick} />
                    </div>

                )
            })
            }
        </div>
    );
};

export default VCisoProfilesList;