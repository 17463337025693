import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, primaryRole } from "../../redux/slices/userSlice";
import axios from "axios";
import { handleSignout } from "../../util/functions/handleSignout";
import baseOC from "icfrontendbase";
import { useSupportSignInClient } from "icfrontendui";

const sharedStorage = baseOC.get("SharedStorage");

const SupportLogin = () => {
    const { search } = useLocation();
    const dispatch = useDispatch();
    const signedIn = useSelector((state) => state.user.signedIn);
    const [sendSupportSignIn] = useSupportSignInClient();

    useEffect(() => {
        handleSupportLogin();
    }, [])

    const handleSupportLogin = async () => {
        try {
            if (signedIn) {
                await handleSignout();
            }

            const query = new URLSearchParams(search, [search]);

            const userId = query.get("user_id");
            const supportId = query.get("support_id");
            if (!userId || !supportId) return;

            // this is temporarily using the request class directly to sign in
            // later we should use the login product component
            let resArr = await Promise.all([
                axios.post("/business/support/slogin", {
                    user_id: userId,
                    support_id: supportId
                }),
                sendSupportSignIn({
                    user_id: userId,
                    support_id: supportId
                })
            ]);
            const legacyRes = resArr[0];
            const libRes = resArr[1];

            if (libRes?.is_error) {
                throw new Error("Error during signin request")
            }

            dispatch(login());
            dispatch(primaryRole(legacyRes.data.primary_role));
            sharedStorage.set("auth_is_signed_in", true);
            sharedStorage.set("auth_primary_role", legacyRes.data.primary_role);
            // set the default whitelabel
            const imageUrlProcurer = baseOC.get("ImageUrlProcurer");
            sharedStorage.set("whitelabel", {
                name: "CISOBot",
                copyright_name: "CISOBot",
                safe_name: "CISOBot",
                logo_url: imageUrlProcurer.procure("cb_logo"),
                customer_support_contact: {
                    email: "customersuccess@cisobot.ai",
                },
                feature_toggle: {
                    assessment_check_remediation_link: true
                }
            })

            window.location.href = "/";

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <div className="loader loading">
                <img src="/favicon.svg" alt="loading" />
            </div>
        </div>
    )
}

export default SupportLogin;