import React, { useState } from "react";
import "./sidebarLink.css";

// Display a collapsable group of links, designed for the sidebar.

// props
//      name         - (string) Display name of the group
//      icon         - (optional, <i> or <img>) The display icon for the group
//      defaultOpen  - (optional, boolean) If true, group will be open by default
//      depth        - (optional, number) Calculate the padding/indent of the group, count from 0
//      children     - (optional, SidebarLink and/or SidebarLinkGroup) 

const SidebarLinkGroup = ({ name, icon = null, defaultOpen = false, depth = 0, children }) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    const indentStyle = {
        paddingLeft: (depth > 0 ? depth * 20 + 40:  depth * 20 + 20 ) + "px"
    };


    return (
        <>
            <div className="sidebar-link">
                <a onClick={() => setIsOpen(!isOpen)} style={{ ...indentStyle }}>
                    <div className="sidebar-link-group-name-icon">
                        <span className="sidebar-link-icon">
                            {icon}
                        </span>
                        <span>
                            {name}
                        </span>
                    </div>
                    <i className={`fa fa-chevron-${isOpen ? "down" : "right"} sidebar-link-group-arrow`} />
                </a>
            </div>

            {isOpen && children}

        </>
    )

}

export default SidebarLinkGroup;