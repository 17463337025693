// Get nested object keys safely  (can really be used for anything that may fail and needs a default value)
//    func: () => someObject.some[works_with_arrays].nested.properties;
//    defaultValue: return this value if object path does not exist
export const getSafe = (func, defaultValue = "") => {
    try {
      if (func()) return func();
      else return defaultValue;
    }
    catch (error) {
      return defaultValue;
    }
  }
  
  