import React from "react";
import "./button.css";

// type => default or primary
const Button = ({ type = "default", text = "", loading = false, size="md", id,...custom }) => {

const handleType=()=>{
    if(type.includes("submit")){
        return "submit"
    }else if(type.includes("reset")){
        return "reset"
    }else{
        return "button"
    }
}

    return (
        <button
        id={id && id}
            type={handleType()}
            className={`btn-base btn-${size} button-type-${type}`}
            {...custom}
        >
            {loading ? <i className="fas fa-spinner button-spinner" /> : null}
            {text}
        </button>
    )
}

export default Button;