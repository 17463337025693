import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import baseOC from "icfrontendbase";
import { toggleSidebarOpen } from "../../../redux/slices/sidebarSlice";
import imageUrl from "../../../util/functions/imageUrl";
import "./topbar.css";

const TopBar = () => {
	const dispatch = useDispatch();
	const isSidebarOpen = useSelector(state => state.sidebar.isSidebarOpen);

	const handleSidebarIconClick = () => {
		dispatch(toggleSidebarOpen())
	}

	const cisobotLogoUrl = useMemo(() => {
        const imageUrlProcurer = baseOC.get("ImageUrlProcurer")
        return imageUrlProcurer.procure("cb_logo");
    }, [])

	return (
		<div className="topbar">
			<div className="topbar-sidebar-toggle" onClick={handleSidebarIconClick}>
				<img src={isSidebarOpen ? imageUrl("i/icons/menu-fold.svg") : imageUrl("i/icons/menu-unfold.svg")} alt="Toggle Sidebar" />
			</div>
			<img src={cisobotLogoUrl} alt="CISOBot" style={{ width: "180px" }}/>
		</div>
	);
};

export default TopBar;
