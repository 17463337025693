import React, { useState, useEffect } from "react";
import axios from "axios";
import Alert from "../../components/UI/alert/Alert"
import Input from "../../components/UI/Input/Input"
import Modal from "../../components/UI/modal/Modal"

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setTechnologiesList } from "../../redux/slices/technologiesSlice"
import { setPopUpAlert } from "../../redux/slices/alertSlice"
import { formatTechnologiesListWithTopics } from "../../util/functions/formatTechnologiesListWithTopics";
import { getErrorMessage } from "../../util/functions/getErrorMessage";
import { setCyberSecurityTopics } from "../../redux/slices/cyberSecurityTopicsSlice"

const EditTechnology = (props) => {

    const dispatch = useDispatch()
    const [editFormData, setEditFormData] = useState(props.selectedTechnologyItemData)
    const [editAlertMessage, setEditAlertMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const regex = /^(?=.*[a-zA-Z0-9])\s*\S/;

    const handleEditFormChange = (e) => {
        const { id, value } = e.target;
        setEditFormData(prev => ({ ...prev, [id]: value }))
    }

    const handleEditCancel = () => {
        props.closeModalResetData()
        setEditAlertMessage("")
        setLoading(false)
    }


    const handleformSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const trimmedName = editFormData.name.trim();
        const isValid = regex.test(trimmedName);

        if (!isValid) {
            dispatch(setPopUpAlert({ message: "Technology name must contains at least one alphanumeric character", type: "error", length: 3000 }))
            setLoading(false)
            return;
        } else {
            setEditFormData(prev => ({ ...prev, name: trimmedName }))
            try {
                const updateTechnologyRes = await axios.post(
                    "/business/technologies/updateTechnology",
                    { ...editFormData, name: trimmedName }
                );
                const cyberSecurityRes = await axios.get("/business/cyberSecurity/cyberSecurityTopics");
                // update VendorList with newly added vendor
                dispatch(setTechnologiesList(formatTechnologiesListWithTopics(updateTechnologyRes.data.vendors, cyberSecurityRes.data)))
                dispatch(setCyberSecurityTopics(cyberSecurityRes.data))
                handleEditCancel()
                dispatch(setPopUpAlert({ message: "Success! Technology updated.", type: "success", length: 2000 }))
            } catch (error) {

                if (error) {
                    const errorMessage = getErrorMessage(error)

                    if (errorMessage === "Successfully updated technology. An error occured while getting the list of technologies. Please try to refresh the page or try again soon.") {
                        handleEditCancel()
                        dispatch(setPopUpAlert({ message: errorMessage, type: "error", length: 7000 }))
                    } else {
                        setLoading(false)
                        setEditAlertMessage({ message: errorMessage, type: 'error' })
                    }

                }
            }
        }
    }



    return (
        <Modal
            title={`Update Technology (${props.selectedTechnologyItemData.name})`}
            onCancel={() => handleEditCancel()}
            onFormSubmit={(e) => handleformSubmit(e)}
            isForm={true}
            loading={loading}
            confirmDisabled={
                loading ? true : false
            }
        >
            <div style={{ display: "flex", flexWrap: "wrap", maxWidth: "500px" }}>
                <Input
                    type="text"
                    id="name"
                    name="name" label={"Name"}
                    required={true}
                    value={editFormData.name}
                    onChange={handleEditFormChange}>
                </Input>

            </div>

            <Alert type={editAlertMessage.type} message={editAlertMessage.message} showIcon={true} />
        </Modal>
    );
};

export default EditTechnology;
