import React from "react";
import "./table.css"

const TableRow = ({ colSpan,rowIndex, item, columns, hover = false, striped = false, name, expandedRows, setExpandedRows, collapsibleColumns }) => {

  const handleFormatData = (columnItem, value, item) => {
    if (!value) {
      value = "N/A"
    }
    let formattedData

    if (columnItem.format) {
      formattedData = columnItem.format(columnItem, value, item)
      return formattedData
    } else {
      return value
    }
  }

  const handleFormatExpandableData = (columnItem, value, item,index) => {
    if (!value) {
      value = "N/A"
    }
    let formattedData

    if (columnItem.format) {
      formattedData = columnItem.format(columnItem, value, item,index)
      return formattedData
    } else {
      return (
        <tr key={`collapsible-${index}`}>
        <th>{columnItem.header}:</th>
        <td>  {item[columnItem.value] ? item[columnItem.value] : "N/A"}</td>
      </tr>
      )
    }
  }



  const handleCollapsibleIcon = (name, index) => {
    //updates which arrow icon to show. checks to see if the row is in the expanded row state. 
    if (expandedRows && expandedRows.get(`${name}-${index}`)) {
      return (

        <div className="collapse-icon-arrow-down">
          <svg width="20" height="20" fill="var( --cisobot-dark-color-blue)" viewBox="0 0 16 16">
            <path d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"></path>
          </svg>
        </div>

      )
    } else {
      return (
        <div className="collapse-icon-arrow-right">
          <svg width="20" height="20" fill="grey" viewBox="0 0 16 16">
            <path d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm4.5 5.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"></path>
          </svg>
        </div>
      )
    }


  }
  const handleCollapsibleClick = (name, index) => {
    // determines if the expandable row is showing. Adding and removing the row from the expandedrow state 
    if (expandedRows && expandedRows.get(`${name}-${index}`)) {
      setExpandedRows((prev) => new Map(prev).set(`${name}-${index}`, false))
    } else {
      setExpandedRows((prev) => new Map(prev).set(`${name}-${index}`, true))
    }
  }


  return (
    <>
      <tr className={`${expandedRows && !expandedRows.get(`${name}-${rowIndex}`) && 'tr-collapsed'} `} >
        {columns && columns.map((columnItem, index) => {

          if (columnItem.value && columnItem.value === "collapse") {
            return (
              <td  className={`${columnItem.value}-col`}  key={`td-${index}`} >
                <span onClick={() => handleCollapsibleClick(name, rowIndex)}> {handleCollapsibleIcon(name, rowIndex)}</span>
              </td>
            )
          }

          return <td key={`td-${index}`}  >{handleFormatData(columnItem, item[`${columnItem.value}`], item)}</td>;

        })}
      </tr>

      {
        collapsibleColumns &&
        <tr className="collapsible-tr">
          <td className="collapsible-td" colSpan={colSpan}>
            <table id={name && `collapsible-${name}`}>
              <tbody>
                {collapsibleColumns && collapsibleColumns.map((columnItem, index) => {
                  return handleFormatExpandableData(columnItem, item[`${columnItem.value}`], item,index)                  
                })}
              </tbody>
            </table>
          </td>
        </tr>
      }
    </>
  );
};

export default TableRow;