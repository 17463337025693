import { createSlice } from '@reduxjs/toolkit'

export const AlertSlice = createSlice({
  name: "alert",
  initialState: {
    popUpAlerts: [],
  },



  reducers: {

    setPopUpAlert: (state, action) => {
      state.popUpAlerts.push({
        id: Math.floor(Math.random() * 10000),
        message: action.payload.message,
        type: action.payload.type,
        length: !action.payload.length ? 2000 : action.payload.length
      });
    },
    removePopUpAlert: (state, action) => {
      state.popUpAlerts = state.popUpAlerts.filter(alert => action.payload.id !== alert.id)


    },
    resetAlerts: (state, action) => {
      state.popUpAlerts = []
    }

  }
})

export const { setPopUpAlert, removePopUpAlert, resetAlerts,setAlert } = AlertSlice.actions

export default AlertSlice.reducer