

import React, { useEffect, useState } from "react";
import "./technologiesList.css"

import * as uuid from 'uuid';
import AssociateTopics from "./associateTopics/AssociateTopics";
import Table from "../../../components/UI/Table/Table"
import Modal from "../../../components/UI/modal/Modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from "react-redux";
import { reset } from "../../../redux/slices/tableSlice"
import { toggleMoreInfoModal, setMoreInfoData } from "../../../redux/slices/tableSlice";
import { setTechnologiesList } from "../../../redux/slices/technologiesSlice"
import { getErrorMessage } from "../../../util/functions/getErrorMessage";
import { PerformRequest } from "../../../util/performRequest"
import { setCyberSecurityTopics } from "../../../redux/slices/cyberSecurityTopicsSlice"
import { formatTechnologiesListWithTopics } from "../../../util/functions/formatTechnologiesListWithTopics";
import { setPopUpAlert } from "../../../redux/slices/alertSlice"
import { toggleAction, actionRowData, } from "../../../redux/slices/tableSlice";
import { faLinkSlash } from '@fortawesome/free-solid-svg-icons'


const TechnologiesList = ({ handleEditClick, handleDeleteClick ,handleTechnologiesAccordionToggle,handleSecurityTopicAccordionToggle, handleAssociateTopicsAccordionToggle, technologiesListAccordionsState}) => {
  const moreInfoData = useSelector((state) => state.table.moreInfoData);
  const showMoreInfoModal = useSelector((state) => state.table.showMoreInfoModal);
  const technologiesListData = useSelector((state) => state.technologies.technologiesList);

  const checkboxPerformRequest = new PerformRequest();

  const dispatch = useDispatch()

  const handleMoreInfoClick = (item, data) => {
    dispatch(setMoreInfoData(item, data))
    dispatch(toggleMoreInfoModal(true))
  }

  const moreInfoModal = (str, maxCount, data, header) => {
    const checkChars = (str) => {

      if (str && str.length > maxCount) {
        return (
          <span  >
            {str.substring(0, maxCount) + '... '}
            <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={() => handleMoreInfoClick({ header: header, value: data })} icon={faCircleInfo} size="1x" color="#40A9FF" />
          </span>
        )
      } else {
        return str
      }
    }
    return (
      checkChars(str)
    )
  }


  const handleTechnologyTopicAssocCheckboxClick = async (e, isChecked, data) => {
    let echoId = uuid.v4()
    let response
    const reqObj = { reqDetails: { endpoint: "/business/associate/technologyTopicAssociation", reqType: "post" }, headers: { ["echo-id"]: echoId }, reqBody: { is_associated: isChecked, cybersecurity_topic_id: data.cybersecurity_topic_id, vendor_id: data.vendor_id } }
    const callback = (reqObj, resObj) => {

      if (resObj.status === 200) {

        if (echoId === resObj.headers["echo-id"]) {

          dispatch(setCyberSecurityTopics(resObj.data))
          dispatch(setTechnologiesList(formatTechnologiesListWithTopics(technologiesListData, resObj.data)))
        }
        response = {
          checkbox: !isChecked,
          disabled: false
        }
        return
      } else if (resObj) {

        if (echoId === resObj.response.headers["echo-id"]) {
          dispatch(setPopUpAlert({ message: getErrorMessage(resObj), type: "error", length: 10000 }))
          response = {
            checkbox: isChecked,
            disabled: false
          }
        }

      }
      return
    }

    try {
      await checkboxPerformRequest.performRequest(reqObj, callback)
    } catch (error) {
      response = {
        checkbox: isChecked,
        disabled: false
      }
    }
    return response
  }



  const columns = [
    {
      value: "topic_hierarchy",
      header: "Topic",
      format: function (item, value) {
        let lastIndexSlash = value.lastIndexOf("/")
        let topic
        let topicHierarchy
        if (lastIndexSlash) {
          topic = value && value.slice(lastIndexSlash + 1)
          topicHierarchy = value && value.slice(0, lastIndexSlash + 1)
        } else {
          topic = value
        }
        return (
          <div >
            <div style={{ color: "#c1c1c1", fontSize: "12px", marginBottom: "3px" }}>
              {moreInfoModal(topicHierarchy, 35, value, item.header)}
            </div>
            <div>{topic && topic}</div>
          </div>
        )

      }
    },
    {
      value: "note",
      header: "Notes",
      format: function (item, value) {
        return (
          <div >
            {moreInfoModal(value, 35, value, item.header)}

          </div>
        )
      }
    },
    {
      value: "status",
      header: "Status",
      format: function (item, value) {
        return (
          <div >
            <div > {value.replace("_", " ")}</div>

          </div>
        )
      }
    },
    {
      value: "unassociate",
      header: "Unassociate",
      format: function (item, value,data) {
   
        return (
          <div style={{cursor:"pointer"}}onClick={() => handleActionClick(data)}>
    
         <FontAwesomeIcon icon={faLinkSlash} color="red" />


          </div>
        )
      }
    }

  ]

  const handleShowMoreTopicInfoCancel = () => {
    dispatch(reset())
  }

  const handleActionClick = (item) => {
    dispatch(actionRowData(item))
    dispatch(toggleAction(true))
  }

  const handleToggleOpenCloseImage = (el) => {
    return (
      <button className="vl-accordion-toggle" >
        {technologiesListAccordionsState.get(el.vendor.vendor_id)?.technologiesListAccordionToggle ?
          <FontAwesomeIcon icon={faChevronDown} />
          : <FontAwesomeIcon icon={faChevronRight} />
        }
      </button>
    )

  }


  return (
    <div style={{ marginTop: "20px" }}>
      {showMoreInfoModal && <Modal
        title={moreInfoData.header}
        onCancel={() => handleShowMoreTopicInfoCancel()}
        footer={false}
      >
        {moreInfoData.value}
      </Modal>
      }
      {technologiesListData && technologiesListData.map((el, index) => {

        return (
          <div key={index}>


            <div className={"vl-accordion-container"} >
              <div className="vl-accordion-item">
                <div className="vl-accordion-header" onClick={() => handleTechnologiesAccordionToggle(el.vendor.vendor_id, technologiesListAccordionsState.get(el.vendor.vendor_id))}>
                  <div className="vl-accordion-header-topic">
                    {handleToggleOpenCloseImage(el)}
                    <span >{el.vendor.name}</span>
                  </div>
                  <div className="vl-actions">
                    <span className="vl-edit-icon" onClick={(e) => handleEditClick(e, true, el.vendor)}>  <FontAwesomeIcon icon={faPenToSquare} size="lg" /></span>
                    <span className="vl-delete-icon" onClick={(e) => handleDeleteClick(e, true, el.vendor)}><FontAwesomeIcon icon={faTrashCan} size="lg" color="red" /></span>
                  </div>
                </div>
      
                <div
                  className={`vl-accordion-body ${technologiesListAccordionsState.get(el.vendor.vendor_id)?.technologiesListAccordionToggle ? 'open' : 'close'}`}
                >


                  <div className="vendor-list-item-body">
                    <div className="item-row">
                      <div className="item"><span className="item-keys">Name:</span><span> {el.vendor.name}</span></div>
                    </div>
                    <div className="item-row">
                      <div className="item"><span className="item-keys">Associated security topics:</span>
                        {el.vendor.topics.length > 0 ?
                          <Table
                            // noDataBody="No Vendors"
                            // noDataHeader="Vendors List"
                            data={el.vendor.topics}
                            columns={columns}
                          name="associated-security-topics-table"
                            style={{ fontSize: "13px", marginTop: "10px" }}
                          >
                          </Table> :
                          <span style={{ marginLeft: "5px" }}>0</span>
                        }
                        <div style={{ marginTop: "10px" }}>
                        
                          <AssociateTopics handleCheckboxClick={handleTechnologyTopicAssocCheckboxClick} handleAssociateTopicsAccordionToggle={handleAssociateTopicsAccordionToggle} handleSecurityTopicAccordionToggle={handleSecurityTopicAccordionToggle} technologiesListAccordionsState={technologiesListAccordionsState} technologiesData={el} />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        )
      })
      }
    </div>
  );
};

export default TechnologiesList;

