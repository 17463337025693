import React, { useEffect, useState, useMemo } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import "./associateTopicsAccordion.css"
import HttpRequestCheckBox from "../../../../components/UI/checkbox/httpRequestCheckbox/httpRequestCheckbox";


const AssociateTopicsAccordion = ({ handleSecurityTopicAccordionToggle, technologiesListAccordionsState, color, data = null, isNested, header, style, children, checkBoxId = null, isChecked, handleCheckboxClick, expandable }) => {

    const handleClick = () => {

        handleSecurityTopicAccordionToggle(data.vendor_id, data.cybersecurity_topic_id
        )
      
    };

    return (
        <div className="at-container">
            <div className={`${isNested ? "at-nested-accordion" : "at-accordion"}`} >
                <div className="at-accordion-item"  >
                    <div className="at-accordion-header" style={{ opacity: "0.8", backgroundColor: color }}>
                        <div>
                            {expandable &&
                                <button className="at-accordion-header-toggle" onClick={handleClick}>
                                    {technologiesListAccordionsState.get(data.vendor_id)?.securityTopicsAccordionToggle?.includes(data.cybersecurity_topic_id
                                    )
                                        ?
                                        <FontAwesomeIcon icon={faChevronDown} />
                                        : <FontAwesomeIcon icon={faChevronRight} />
                                    }
                                </button>
                            }
                            <span className="at-accordion-header-name"  >{header}</span>
                        </div>
                        <HttpRequestCheckBox isChecked={isChecked} handleCheckboxClick={handleCheckboxClick} data={data} id={`checkbox-${checkBoxId}`} />
                    </div>
                    <div
                        className={`at-accordion-body ${expandable && technologiesListAccordionsState.get(data.vendor_id)?.securityTopicsAccordionToggle?.includes(data.cybersecurity_topic_id
                        ) ? 'open' : 'close'}`} style={style?.body}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssociateTopicsAccordion;

