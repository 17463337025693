import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { handleSignout } from "./util/functions/handleSignout";
import Layout from "./components/layout/Layout";
import PopUpAlert from "./components/UI/alert/PopUpAlert";
import TimeoutWarning from "./components/timoutWarning/TimeoutWarning";
import Dashboard from "./pages/dashboard/Dashboard";
import Technologies from "./pages/technologies/Technologies";
import TechnologyDiscovery from "./pages/technologyDiscovery/TechnologyDiscovery";
import VCisoMarketplace from "./pages/vCisoMarketplace/VCisoMarketplace";
import Login from "./pages/sign/Login";
import SupportLogin from "./pages/support/SupportLogin";
import SignUpConfirm from "./pages/sign/SignUpConfirm";
// import SignUp from "./pages/sign/SignUp";
import ForgotPassword from "./pages/sign/ForgotPassword";
import ResetPassword from "./pages/sign/ResetPassword";
import Loading from "./components/UI/loading/Loading";


const url = (path) => {
    // enforce url path pattern with leading slash
    if (path[0] === "/") return path;
    return `/${path}`;
};

const App = () => {
    const signedIn = useSelector((state) => state.user.signedIn);
    const primary_role = useSelector((state) => state.user.primaryRole);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        if (
            signedIn === false &&
            (
                window.location.pathname === "" ||
                window.location.pathname === "/" ||
                window.location.pathname === "/login" ||
                window.location.pathname === "/login/"
            )
        ) {
            handleSignout()
        }
    }, [])

    // support login is separate because it handles the session differently
    // when there is already a session on the support login page, it waits for signout before signing in
    if (window.location.pathname.startsWith("/slogin")) {
        return (
            <BrowserRouter basename={"/"}>
                <Routes>
                    <Route exact path={url("/slogin")} element={<SupportLogin />} />
                </Routes>
            </BrowserRouter>
        )
    }

    // Unauthorized user view
    if (signedIn === false) {
        return (
            <BrowserRouter basename={"/"}>
                <Routes>
                    {/* Blank path is used in health check */}
                    <Route exact path={""} element={<Login setShowLoading={setShowLoading} />} />
                    <Route exact path={"/"} element={<Login setShowLoading={setShowLoading} />} />
                    <Route exact path={url("/login")} element={<Login setShowLoading={setShowLoading} />} />
                    <Route exact path={url("/reset_password")} element={<ForgotPassword />} />
                    <Route exact path={url("/reset_password/:token")} element={<ResetPassword />} />
                    {/* <Route exact path={url("/signup")} element={<SignUp />} /> */}
                    <Route exact path={url("/confirm_signup/:token")} element={<SignUpConfirm />} />
                    <Route path="*" element={<Navigate to="/login" replace />} />
                </Routes>
            </BrowserRouter>
        );
    }

    // Signed in as incorrect primary_role view
    if (primary_role && primary_role !== "org" && primary_role !== "cisobot") {
        window.location.href = `/${primary_role}/`;
        return;
    }

    if (showLoading) {
        return <Loading />;
    }

    // Authorized user view
    return (
        <BrowserRouter basename={"/"}>
            <Layout>
                <PopUpAlert />
                <TimeoutWarning />
                <Routes>
                    {/* Authorized user routes */}
                    {/* <Route exact path={url("/dashboard/")} element={<Dashboard />} /> */}
                    <Route exact path={url("/dashboard/:chartType")} element={<Dashboard />} />
                    <Route
                        exact
                        path={url("/technologies/technologiesList")}
                        element={<Technologies />}
                    />
                    <Route
                        exact
                        path={url("/technologies/technologyDiscovery")}
                        element={<TechnologyDiscovery />}
                    />
                    <Route
                        exact
                        path={url("/vCISOMarketplace")}
                        element={<VCisoMarketplace />}
                    />
                    <Route exact path="/dashboard" element={<Navigate to="/dashboard/sunburst/" />} />
                    <Route path="*" element={<Navigate to="/dashboard" replace />} />
                </Routes>
            </Layout>
        </BrowserRouter>
    );
}

export default App;
