import React, { useState, useEffect } from "react";
import "./popover.css"

// Show a popover message/component when an element is clicked
// params
//      triggerEl => show the popover when clicked
//      children (inner jsx) => the content to show in the popover

const Popover = ({ triggerEl, children }) => {
    const [ showPopover, setShowPopover ] = useState(false);


    // if user clicks outside the popover, close it
    useEffect(() => {
        const handleClick = e => {
            if (!document.querySelector(".popover").contains(e.target)) {
                setShowPopover(false);
            }
        };
        document.addEventListener("mousedown", handleClick);
        
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);


    return (
        <div className="popover">
            <div className="popover-trigger" onClick={e => {
                e.stopPropagation();
                setShowPopover(!showPopover)
            }}>
                {triggerEl}
            </div>
            {showPopover && (
                <div className="popover-content">
                    {children}
                </div>
            )}
        </div>
    )
}

export default Popover;