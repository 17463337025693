import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSidebarOpen } from "../../redux/slices/sidebarSlice";
import SideBar from "./sidebar/SideBar";
import TopBar from "./topbar/TopBar";
import "./layout.css";

// Display the topbar, sidebar, and a scrollable content box

// props
//      children - (optional, jsx) Intended for routes, but could hardcode a specific component

const Layout = ({ children }) => {
    const dispatch = useDispatch();
    const isSidebarOpen = useSelector(state => state.sidebar.isSidebarOpen);

    const handleContentClick = () => {
        if (isSidebarOpen && window.innerWidth <= 1000) {
            dispatch(setSidebarOpen(false))
        }
    }

    return (
        <div className="layout">
            <TopBar />
            <div className="layout-content-sidebar-row">
                <SideBar />
                <div className="layout-content" onClick={handleContentClick}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Layout;