import { createSlice } from '@reduxjs/toolkit'

export const tableSlice = createSlice({
  name: 'table',
  initialState: {
    showAction: false,
    
    showMoreInfoModal:false,
    actionRowData: null,
    moreInfoData:null,
   
  },
  reducers: {
setExpandedRows:(state,action)=>{

},

    toggleAction: (state, action) => {
    
      state.showAction = action.payload

    },

    actionRowData: (state, action) => {
      state.actionRowData = action.payload
    },

    toggleMoreInfoModal: (state, action) => {
      state.showMoreInfoModal = action.payload

    },

    setMoreInfoData: (state, action) => {
      state.moreInfoData = action.payload
    },


    reset: (state) => {
      
        state.showAction = false,
        state.actionRowData = null
        state.showMoreInfoModal = false,
        state.moreInfoData = null
  
    }


  }
})

// Action creators are generated for each case reducer function
export const { toggleAction, actionRowData, toggleMoreInfoModal, setMoreInfoData, reset } = tableSlice.actions

export default tableSlice.reducer