import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { primaryRole } from "../../redux/slices/userSlice";
import Sign from "../../components/sign/Sign";
import { getSafe } from "../../util/functions/getSafe";

const SignUpConfirm = () => {
    const [ loading, setLoading ] = useState(false);
    const [ type, setType ] = useState("signup_confirm");
    const { token } = useParams();
    const [ errorMessage, setErrorMessage ] = useState("");

    const dispatch = useDispatch();

    const handleSignUpConfirm = async e => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage("");
        
        const formData = {
            email: e.target[0].value,
            password: e.target[1].value
        }

        try {
            await axios
                .post(
                    "/business/account/signupConfirm", 
                    {
                        email: formData.email,
                        token: token
                    }
                )
                .then(async () => {
                    // bad code, plz fix this later
                    setType("signup_confirm_success-login_false")
                    await axios
                        .post(
                            "/business/account/login", 
                            {
                                email: formData.email,
                                password: formData.password
                            }
                        )   
                        .then(res => {
                            dispatch(primaryRole(res.data.primary_role))
                            setType("signup_confirm_success-login_true")
                        })
                        .catch(error => {
                            setType("signup_confirm_success-login_false")
                            return;
                        })
                })
                   
        } catch(error) {
            setLoading(false);
            const msg = getSafe(() => error.response.data.message, "");
            setErrorMessage(msg);
        }
    }

    return (
        <Sign 
            title="Confirm Sign Up"
            subtitle="Cloud Infrastructure Security and Compliance Starts Here"
            type={type} 
            formComplete={handleSignUpConfirm}
            errorMessage={errorMessage}
            loading={loading}
            email=""
        />
    );
}

export default SignUpConfirm;
