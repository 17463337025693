import React, { useState } from "react";
import axios from "axios";
import Sign from "../../components/sign/Sign";
import { getSafe } from "../../util/functions/getSafe";
import { useParams } from "react-router-dom";

const ResetPassword = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [ type, setType ] = useState("resetpassword");
  const { token } = useParams()

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setErrorMessage("");
   
    const formData = {
      password: e.target[0].value,
      confirm_password: e.target[1].value
    };
    if(formData.password === formData.confirm_password) {
        setLoading(true);
        try {
        let res = await axios.post("/business/account/resetPassword", {
            token: token,
            password: formData.password,
        });
        
        setType("signup_confirm_success-login_false")
        } catch (error) {
        setLoading(false);
        const msg = getSafe(() => error.response.data.message, "Something went wrong, please try again after sometime.");
        setErrorMessage(msg);
        }
    } else {
        setErrorMessage("The password did not match.")
    }
  };

  return (
    <Sign
      title="Reset Password"
      type={type}
      formComplete={handleResetPassword}
      errorMessage={errorMessage}
      loading={loading}
    />
  );
};

export default ResetPassword;