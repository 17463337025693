// Returns variable's value based on the environment
// args
//      key (string) => the variable key 
// return
//      (any type) value of the key

const env = key => {
 
    let curEnv = "local";

    if (window.location.href.includes("//app")) curEnv = "prod";
    if (window.location.href.includes("//dev")) curEnv = "dev";

    let value = "";
    if (variables[curEnv]?.[key]) {
        value = variables[curEnv][key];
    }

    return value;
}

const getBaseUrl = () => {
    const fullUrl = window.location.href;
    const portIndex = fullUrl.slice(6).indexOf(":") + 6;
    const baseUrl = fullUrl.slice(0, portIndex);
    return baseUrl;
}

// NOTE: This is not a secure storage
const variables = {
    local: {
        ENV: "local",
        CISOBOT_IMAGES_URL_BASE: `${getBaseUrl()}:5130/`,
        MAIN_WEBSITE_URL: `${getBaseUrl()}:6001`
    },
    dev: {
        ENV: "dev",
        CISOBOT_IMAGES_URL_BASE: "https://dev-images.cisobot.ai/",
        MAIN_WEBSITE_URL: "https://dev.cisobot.ai"
    },
    prod: {
        ENV: "prod",
        CISOBOT_IMAGES_URL_BASE: "https://images.cisobot.ai/",
        MAIN_WEBSITE_URL: "https://www.cisobot.ai"
    }
}

export default env;