import { createSlice } from '@reduxjs/toolkit'


export const TechnologiesSlice = createSlice({
  name: "technologies",
  initialState: {
    technologiesList: null,
  },



  reducers: {

   setTechnologiesList: (state, action) => {
     state.technologiesList= action.payload
      
    },
 
  }
})

export const { setTechnologiesList } = TechnologiesSlice.actions

export default TechnologiesSlice.reducer