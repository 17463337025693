import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    signedIn: false,
    primaryRole: "",
  },
  reducers: {


    login: state => {
      state.signedIn = true
    
    },

    primaryRole: (state, action) => {
      
      state.primaryRole = action.payload
    },
    signOut: state => {
   //root Reducer uses this reducer function to reset the redux state on signout
   state.signedIn = false
    }


  }
})

// Action creators are generated for each case reducer function
export const { login, signOut, primaryRole } = userSlice.actions

export default userSlice.reducer