import React from "react";
import { useSelector } from "react-redux";
import { handleSignout } from "../../../../util/functions/handleSignout";
import Popover from "../../../UI/popover/Popover";
import "./userInfo.css";

const UserInfo = () => {
    const primaryRole = useSelector((state) => state.user.primaryRole);

    return (
        <div className="userinfo">
            <Popover
                triggerEl={(
                    <div className="userinfo-popover-trigger">
                        <i className="fas fa-user-circle" />
                        <p>Account</p>
                        <i className="fa fa-chevron-down" />
                    </div>
                )}
            >
                <div className="userinfo-popover-content">
                    {primaryRole === "org" && (
                        <a href="/comp/account/usersettings/profile">
                            User Settings
                        </a>
                    )}
                    <div onClick={handleSignout}>
                        Logout
                    </div>
                </div>
            </Popover>
        </div>
    )
}

export default UserInfo;