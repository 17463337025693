import { createSlice } from '@reduxjs/toolkit'

export const RequestSlice = createSlice({
    name: 'request',
    initialState: {
        lastRequestUrl: "",
    },
    reducers: {

        lastRequestUrl: (state, action) => {
            state.lastRequestUrl = action.payload
        },



    }
})

// Action creators are generated for each case reducer function
export const { lastRequestUrl } = RequestSlice.actions

export default RequestSlice.reducer