import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./popUpAlert.css"
import { removePopUpAlert, resetAlerts } from "../../../redux/slices/alertSlice"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faCircleXmark, faCircleCheck, faCircleInfo } from '@fortawesome/free-solid-svg-icons'

const PopUpAlert = () => {

    const alerts = useSelector(state => state.alert.popUpAlerts);

    const dispatch = useDispatch();

    useEffect(() => {

        if (alerts && alerts.length > 0) {
            //find last alert abject set in the alerts array in redux state
            const alert = alerts[alerts.length - 1]
            const { length } = alert
            //remove that alert, and use the length property in that alert object to set the time for the setTimeout.
            setTimeout(() => {
                dispatch(removePopUpAlert(alert))
            }, length);
        }

    }, [alerts]);


    useEffect(() => {
        // on initial page load reset alerts array in redux state
        dispatch(resetAlerts())
    }, []);


    let alertList

    return (
        <div className={`alert-parent`}>

            {alertList = alerts && alerts.map((alert,index) => {

                return (
                    <div className={`alert-container`} key={index} style={{top: index > 0 && index * 60}}>
                        <div className={`alert`}>
                            <div className={`alert-notice`}>
                                <span>  {alert.type === "error" && <FontAwesomeIcon icon={faCircleXmark} size="1x" color="#FF4D4F" />}
                                    {alert.type === "success" && <FontAwesomeIcon icon={faCircleCheck} size="1x" color="#52C41A" />}
                                    {alert.type === "warning" && <FontAwesomeIcon icon={faCircleExclamation} size="1x" color="#FAAD14" />}
                                    {alert.type === "info" && <FontAwesomeIcon icon={faCircleInfo} size="1x" color="#40A9FF" />}
                                </span>
                                <span> {alert.message} </span>
                            </div>
                        </div>
                    </div>
                )
            })}

        </div>
    )

}

export default PopUpAlert