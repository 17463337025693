

import React, { useState } from "react";
import env from "../../../util/functions/env"
import Modal from "../../../components/UI/modal/Modal";
import "./vCisoProfileModal.css"

const VCisoProfileModal = ({ profile, handleProfileModalCancel }) => {
    const [loading, setLoading] = useState(true);

    const imageOnErrorHandler = (event) => {
        event.currentTarget.src = `${env("CISOBOT_IMAGES_URL_BASE")}i/Portrait_Placeholder.png`
        event.currentTarget.className = "error"
    };

    const handleImage = (data) => {
        if (data) {
            return profile.profile.image_url
        } else {
            return `${env("CISOBOT_IMAGES_URL_BASE")}i/Portrait_Placeholder.png`
        }
    }


    return (
        <Modal
            title={"Virtual CISO"}
            onCancel={() => handleProfileModalCancel()}
            footer={false}
        >
            <div className="vciso-profile-modal" >
                <div className="vciso-modal-card-left" >
                    <div className="vciso-modal-card-img">
                        <div style={{ display: loading ? "block" : "none" }}>
                        </div>
                        <img style={{ display: loading && "none" }} src={handleImage(profile.profile.image_url)}
                            onError={imageOnErrorHandler}
                            onLoad={() => setLoading(false)}
                        />
                    </div>
                    <div className="vciso-modal-card-body">
                        <div className="vciso-modal-card-title">
                            {profile.profile.first_name} {profile.profile.last_name}
                        </div>
                        <div className="vciso-modal-card-email">
                            {profile.profile.email_address}
                        </div>
                        <div className="vciso-modal-card-title-line">
                            {profile.profile.title_line}
                        </div>
                    </div>
                </div>
                <div className="vciso-modal-card-right">
                    <div className="vciso-modal-card-description">
                        {profile.profile.description}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default VCisoProfileModal;


