export const statusCodeToName = status_code => {
    if (status_code === "resolved") {
      return "Resolved";
    }
    if (status_code === "in_progress") {
      return "In Progress";
    }
    if (status_code === "to_do") {
      return "To Do";
    }
    if (status_code === "not_applicable") {
      return "Not Applicable";
    }
    if (status_code === "default") {
      return "Default";
    }
  
    return "Default";
  }