import React from "react";
import Button from "../button/Button";
import "./modal.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faXMark} from '@fortawesome/free-solid-svg-icons'
const Modal = (
    {
        title = "",
        onCancel,
        onConfirm,
        onFormSubmit,
        children,
        loading = false,
        confirmDisabled = false,
        cancelText = "Cancel",
        submitText = "Submit",
        isForm = false,
        footer = true
    }
) => {

    // check if the click was targeting the background (outside the modal)
    // if so, close the modal
    const checkOutsideClick = e => {
        if (e.target.className === "modal-outer" || e.target.className === "modal-inner") {
            onCancel()
        }
    }

    return (
        <div className="modal-outer" onClick={e => checkOutsideClick(e)}>
            <div className="modal-inner">
                <div className="modal-box">
                    <div className="modal-header">
                        <span className="modal-header-name">{title}</span>
                        <span className="modal-header-close-icon" style={{cursor:"pointer"}} onClick={() => onCancel()}>
                   
                        ×
                        </span>
                    
                    </div>
                    {isForm ?
                        <div className="modal-body">
                            <form onSubmit={onFormSubmit}>
                                {children}

                                <div className="modal-actions">
                                    <Button
                                        onClick={() => onCancel()}
                                        style={{ marginRight: "15px" }}
                                        text={cancelText}
                                    />
                                    <Button
                                        type="submit"

                                        loading={loading}
                                        disabled={confirmDisabled}
                                        text={submitText}
                                    />
                                </div>
                            </form>



                        </div> :
                        <div className="modal-body">
                            {children}
                            {footer &&
                                <div className="modal-actions">
                                    <Button
                                        onClick={() => onCancel()}
                                        style={{ marginRight: "15px" }}
                                        text={cancelText}
                                    />
                                    <Button
                                        type="submit"
                                        onClick={() => onConfirm()}
                                        loading={loading}
                                        disabled={confirmDisabled}
                                        text={submitText}
                                    />
                                </div>
                            }
                        </div>
                    }

                </div>
            </div>

        </div>
    )
}

export default Modal;