import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./statusForm.css";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePlus, faSquareMinus } from '@fortawesome/free-regular-svg-icons'
import Modal from "../../../../components/UI/modal/Modal";
import Select from "../../../../components/UI/select/Select";
import Alert from "../../../../components/UI/alert/Alert";
import Button from "../../../../components/UI/button/Button"
import HttpRequestCheckBox from "../../../../components/UI/checkbox/httpRequestCheckbox/httpRequestCheckbox";
import { handleStatusColor } from "../../../../util/functions/handleStatusColor";
import { formatTechnologiesListWithTopics } from "../../../../util/functions/formatTechnologiesListWithTopics";
import { getErrorMessage } from "../../../../util/functions/getErrorMessage";
import { setPopUpAlert } from "../../../../redux/slices/alertSlice";
import { setCyberSecurityTopics } from "../../../../redux/slices/cyberSecurityTopicsSlice"
import { setTechnologiesList } from "../../../../redux/slices/technologiesSlice"
import { PerformRequest } from "../../../../util/performRequest"

import * as uuid from 'uuid';

const checkboxPerformRequest = new PerformRequest();

const StatusForm = ({ formData, handleCancel }) => {

  const [noteValue, setNoteValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [topicFormFields, setTopicFormFields] = useState({ state: "" });
  const technologiesList = useSelector((state) => state.technologies.technologiesList);

  const [showAssociateVendorList, setShowAssociateVendorList] = useState(false)
  const [requestStatus, setRequestStatus] = useState({ state: { state: null, color: null, }, note: { state: null, color: null, isActive: false } });

  const dispatch = useDispatch()
  const technologiesListData = useSelector((state) => state.technologies.technologiesList);

  const firstUpdate = useRef(false);
  const statusIsActive = useRef(false);


  const handleRequestStatus = (name, value, color) => {
    setRequestStatus(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        state: value,
        color: color,
      }
    }));
  }

  // const handleTopicFormFields = (name, value) => {
  //   setTopicFormFields(prevState => ({
  //     ...prevState,
  //     [name]: value

  //   }));
  // }

  useEffect(() => {

    // add vendors to request state so we can manage the active state when associating a vendor
    if (formData && formData.name && formData.cybersecurity_topic_status) {
      let technologiesIds = technologiesList.reduce((total, curVal) => {
        total[curVal.vendor.vendor_id] = { state: null, color: null }
        return total
      }, requestStatus)
      setRequestStatus(technologiesIds)

      setNoteValue(formData.cybersecurity_topic_status.note);
      let newFormData = structuredClone(formData.cybersecurity_topic_status)
      delete newFormData.note
      delete newFormData["state_dt"]
      setTopicFormFields(newFormData)

    }
  }, []);




  useEffect(() => {

    if (firstUpdate.current) {
      let isActive
      
      const timeOutId = setTimeout(async () => {
        isActive = true;
        handleRequestStatus("note", "saving", "#1E90FF")

        try {

          const updateRes = await axios.post(
            "/business/cyberSecurity/updateTopicStatus",
            { note: noteValue, cybersecurity_topic_id: formData.cybersecurity_topic_id }
          );
          dispatch(setCyberSecurityTopics(updateRes.data))

          if (isActive) {
            handleRequestStatus("note", "saved", "green")
            setTimeout(() => {
              handleRequestStatus("note", "")
            }, 700)
          }

        } catch (error) {

          dispatch(setPopUpAlert({ message: getErrorMessage(error), type: "error", length: 5000 }))
          if (isActive) {
            handleRequestStatus("note", "Error", "red")
            setTimeout(() => {
              handleRequestStatus("note", "")
            }, 1000)

          }
        }
      }, 1000);

      return () => {
        if (isActive) {
          isActive = false;
        }
        clearTimeout(timeOutId);
      }
    }
  }, [noteValue])


  const handleSelectChange = async (value) => {

    firstUpdate.current = true;
    let id = uuid.v4()

    if (statusIsActive.current !== id) {
      statusIsActive.current = id;
    }

    setTopicFormFields(prevState => ({
      ...prevState,
      state: value
    }));

    if (firstUpdate.current === true) {

      const handleRequest = async () => {
        handleRequestStatus("state", "typing", "orange")

        try {
          handleRequestStatus("state", "saving", "#1E90FF")

          const updateRes = await axios.post(
            "/business/cyberSecurity/updateTopicStatus",
            { state: value, cybersecurity_topic_id: formData.cybersecurity_topic_id }
          );
          dispatch(setCyberSecurityTopics(updateRes.data))

          if (statusIsActive.current === id) {
            handleRequestStatus("state", "saved", "green")
            setTimeout(() => {
              handleRequestStatus("state", "")
            }, 700)
          }
        } catch (error) {
          dispatch(setPopUpAlert({ message: getErrorMessage(error), type: "error", length: 7000 }))
          if (statusIsActive.current === id) {
            handleRequestStatus("state", "Error", "red")
            setTimeout(() => {
              handleRequestStatus("state", "")
            }, 1000)

          }
        }
      }
      handleRequest()
    }
  }

  const handleVendorTopicAssocCheckboxClick = async (e, isChecked, data) => {

    let echoId = uuid.v4()
    let response

    handleRequestStatus(data.vendor_id, "saving", "#1E90FF")
    const reqObj = { reqDetails: { endpoint: "/business/associate/technologyTopicAssociation", reqType: "post" }, headers: { ["echo-id"]: echoId }, reqBody: { is_associated: isChecked, cybersecurity_topic_id: data.cybersecurity_topic_id, vendor_id: data.vendor_id } }
    const callback = async (reqObj, resObj) => {

      if (resObj.status === 200) {
        if (echoId === resObj.headers["echo-id"]) {

          dispatch(setCyberSecurityTopics(resObj.data))
          dispatch(setTechnologiesList(formatTechnologiesListWithTopics(technologiesListData, resObj.data)))
          handleRequestStatus(data.vendor_id, "saved", "green")
          setTimeout(() => {
            handleRequestStatus(data.vendor_id, "")
          }, 700)
          response = {
            checkbox: !isChecked,
            disabled: false
          }
        }
      } else if (resObj) {
        if (echoId === resObj.response.headers["echo-id"]) {
          dispatch(setPopUpAlert({ message: getErrorMessage(resObj), type: "error", length: 8000 }))
          response = {
            checkbox: isChecked,
            disabled: false
          }
          handleRequestStatus(data.vendor_id, "Error", "red")
          setTimeout(() => {
            handleRequestStatus(data.vendor_id, "")
          }, 1000)
        }
      }
    }

    await checkboxPerformRequest.performRequest(reqObj, callback)

    return response


  }


  const handleChange = async (e) => {
    handleRequestStatus("note", "typing", "orange")
    firstUpdate.current = true
    setNoteValue(e.target.value)
    
  };

  const handleTopicVendorAssocCheck = (vendor) => {
    let isAssociated = false
    if (vendor.vendor.topics[0]) {

      isAssociated = vendor.vendor.topics.some(el => {
        return el.cybersecurity_topic_id === formData.cybersecurity_topic_id
      })
    }
    return isAssociated
  }




  const formatAssociateTopicButtonText = () => {
    let technologyText
    if(technologiesList.length > 1){
      technologyText = "Associate technologies"
    }else{
      technologyText = "Associate technology"
    }
    if (showAssociateVendorList) {


      return (
        <div>
          <FontAwesomeIcon icon={faSquareMinus} />
          <span style={{ marginLeft: "10px" }}>
            

            {technologyText}
          </span>
        </div>
      )
    } else {
      return (
        <div>
          <FontAwesomeIcon icon={faSquarePlus} />
          <span style={{ marginLeft: "10px" }}>
          {technologyText}
          </span>
        </div>
      )
    }
  }


  return (
    <Modal
      title={formData?.name}
      onCancel={() => handleCancel()}
      footer={false}
      // confirmDisabled={
      //   statusValue === formData.cybersecurity_topic_status.state &&
      //   noteValue === formData.cybersecurity_topic_status.note
      // }
      loading={loading}
    >
      <div className="status-form">
        {topicFormFields.state &&
          <span>
            <label htmlFor="status">Status: <span style={{ color: requestStatus.state.color }}>{requestStatus.state.state}</span></label>
            <Select
              name="status"
              defaultValue={topicFormFields?.state}
              onChange={handleSelectChange}
              options={[
                { value: "default", name: "Default" },
                {
                  value: "not_applicable",
                  name: (
                    <span>
                      <span style={{ color: handleStatusColor("not_applicable") }}>● </span>Not Applicable{" "}
                    </span>
                  ),
                },
                {
                  value: "to_do",
                  name: (
                    <span>
                      <span style={{ color: handleStatusColor("to_do") }}>● </span>To Do{" "}
                    </span>
                  ),
                },
                {
                  value: "in_progress",
                  name: (
                    <span>
                      <span style={{ color: handleStatusColor("in_progress") }}>● </span>In Progress
                    </span>
                  ),
                },
                {
                  value: "resolved",
                  name: (
                    <span>
                      <span style={{ color: handleStatusColor("resolved") }}>● </span>Resolved
                    </span>
                  ),
                },
              ]}
            />
          </span>
        }
        <div style={{ display: "flex", flexDirection: "column", marginTop: "10px" }}>
          <label htmlFor="notes" >Notes:  <span style={{ color: requestStatus.note.color }}>{requestStatus.note.state}</span> </label>
          <textarea
            name="notes"
            value={noteValue}
            cols={40}
            placeholder=""
            onChange={(event) => handleChange(event)}
          />
        </div>
        {technologiesList && technologiesList[0] &&
          <div >
            <Button type={"primary"} style={{ fontSize: "14px", margin: "10px 0px" }} text={formatAssociateTopicButtonText()} onClick={() => setShowAssociateVendorList(!showAssociateVendorList)} />
            <div className="associate-vendor-container">
              {
                showAssociateVendorList &&
                technologiesList && technologiesList.map((el, index) => {

                  return (
                    <div key={index} className="associate-vendor-list">
                      <div>
                        {el.vendor.name}
                      </div>
                      <div style={{ display: "flex" }}>
                        <span style={{ marginRight: "10px", color: requestStatus[el.vendor.vendor_id]["color"] }}>{requestStatus[el.vendor.vendor_id]["state"]}</span>
                        <HttpRequestCheckBox data={{ cybersecurity_topic_id: formData.cybersecurity_topic_id, vendor_id: el.vendor.vendor_id }} handleCheckboxClick={handleVendorTopicAssocCheckboxClick} isChecked={handleTopicVendorAssocCheck(el)} id={`checkbox-${el.vendor.vendor_id + formData.cybersecurity_topic_id}`} />
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        }
      </div>
    </Modal>
  );
};

export default StatusForm;
