export const getErrorMessage = error => {

    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      return error.response.data.message;
    }
  
    return "Error while processing request, please try again soon";
  }