import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSidebarOpen } from "../../../../redux/slices/sidebarSlice";
import { Link, useLocation } from "react-router-dom";
import "./sidebarLink.css";

// Display a link to a page, designed for the sidebar.
// Optionally can be used as a child of SidebarLinkGroup

// props
//      href         - (string) The url or path used in the link
//      name         - (string) Display name of the link
//      icon         - (optional, <i> or <img>) The display icon for the link
//      isExternal   - (optional, boolean) If true, react router will not be used
//      isFakeGroup  - (optional, boolean) If true, an arrow will be shown to simulate style of a group 
//      depth        - (optional, number) Calculate the padding/indent of the link, count from 0

const SidebarLink = ({ href, name, icon = null, isExternal = false, isFakeGroup = false, depth = 0 }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        if (location.pathname === href) {
            setIsSelected(true);
        } else if (isSelected) {
            setIsSelected(false);
        }
    }, [location]);

    const handleLinkClick = () => {
        if (window.innerWidth <= 1000) {
            dispatch(setSidebarOpen(false))
        }
    }

    const indentStyle = {
        paddingLeft: (depth *2 * 20 + 20) + "px"
    }

    const linkContent = (
        <>
            <div className="sidebar-link-group-name-icon">
                <span className="sidebar-link-icon">
                    {icon}
                </span>
                <span  className="sidebar-link-name">
                {name}
                </span>
            </div>
            {isFakeGroup && <i className="fa fa-chevron-right sidebar-link-group-arrow" />}
        </>
    )

    return (
        <div
            className={`sidebar-link ${isSelected ? "sidebar-link-selected" : ""}`}
            onClick={handleLinkClick}
        >
            {isExternal === false ? (
                <Link to={href} style={{ ...indentStyle }}>
                    {linkContent}
                </Link>
            ) : (
                <a href={href} style={{ ...indentStyle }}>
                    {linkContent}
                </a>
            )}
        </div>
    )
}

export default SidebarLink;