import React, { useRef, useLayoutEffect, useEffect, useState } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { handleStatusColor } from "../../../../util/functions/handleStatusColor";
import { handleCriticality } from "../../../../util/functions/handleCriticality";

const HorizontalPartition = ({ cyberSecurityTopics, handleTopicSelected }) => {
  const [chartHeight, setChartHeight] = useState("10px");
  const [chartDepth, setChartDepth] = useState(2);
  const horizontalChartRef = useRef(null);


  const chartFormatter = () => {
    let data = structuredClone(cyberSecurityTopics);

    let formattedData;
    formattedData = {
      name: "cybersecurity_topic_summary",
      sub_topics: data["cybersecurity_topic_summary"],
    };

    const _getDepth = (topics, depth = 0) => {
      let newDepth = depth;
      topics.map((topic) => {
        const subTreeDepth = _getDepth(topic.sub_topics, depth + 1);
        newDepth = subTreeDepth > newDepth ? subTreeDepth : newDepth;
      });

      return newDepth;
    };

    let maxDepth = _getDepth(formattedData.sub_topics)

    let leafNodes = 0;

    const formatter = (data, depth = 0) => {
      depth++;
      data.sub_topics.forEach((subtopic) => {
        subtopic.formattedName = formatName(
          subtopic.name,
          subtopic.cybersecurity_topic_status.state
        );
        subtopic.formattedPriority = formatPriority(
          subtopic.scalePriority
        );
        subtopic.formattedState = formatState(
          subtopic.cybersecurity_topic_status.state
        );

        //giving every slice a value of 10 so every slice in the chart has the same width
        if (!subtopic.sub_topics.length) {
          if (subtopic.name != "") {
            subtopic.value = 10
            leafNodes = leafNodes + 1

            if (depth == maxDepth) {
              subtopic.sub_topics = [{
                name: "",
                cybersecurity_topic_status: { state: "" },
                sub_topics: [],
              }]
            }
          }
        }

        formatter(subtopic, depth);
      });
      return data;
    };
    formatter(formattedData);
    let cellSize;
    if (leafNodes <= 10) {
      cellSize = 50;
    } else {
      cellSize = 30;
    }
    setChartHeight(`${leafNodes * cellSize}px`);
    return formattedData;


  };

  const formatState = (state) => {
    let status = state?.replace("_", " ");
    let statusValue = status.charAt(0).toUpperCase() + status.slice(1);
    return statusValue;
  }

  const formatPriority = (scalePriority) => {
    let priorityValue = handleCriticality(scalePriority)?.criticality;
    if (priorityValue) return `\n [bold]Priority:[/] ${priorityValue}`;
    return ""
  }

  const formatName = (name, status) => {
    let formattedStatus = status.replace("_", " ");
    let fName;

    if (status === "default") {
      fName = `[bold]${name} `;
    } else {
      // fName = `[bold]${name}[/] ([${handleStatusColor(
      //   status
      // )}]● [/]${formattedStatus})`;

      fName = `[bold]${name} [${handleStatusColor(status)}]● [/]`;
    }
    return fName;
  };

  useLayoutEffect(() => {
    let root = am5.Root.new("horizontalChart");

    const myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll({
      fontSize: "1em",
    });

    myTheme
      .rule("ColorSet")
      .set("colors", [am5.color(0x5aaa95), am5.color(0x86a873)]);

    root.setThemes([am5themes_Animated.new(root), myTheme]);

    let chart = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    );
    let series = chart.children.push(
      am5hierarchy.Partition.new(root, {
        valueField: "value",
        categoryField: "formattedName",
        childDataField: "sub_topics",
        orientation: "horizontal",
        // initialDepth: 10,
        topDepth: 1,
      })
    );

    series.bullets.push(function (root, series, dataItem) {
      let icon = dataItem.dataContext?.scalePriority;
      let iconData = handleCriticality(icon);
      return am5.Bullet.new(root, {
        locationX: 0.02,
        locationY: 0.5,
        sprite: am5.Picture.new(root, {
          width: 25,
          height: 25,
          centerX: am5.percent(5),
          centerY: am5.percent(50),
          src: iconData?.icon,
        })
      });
    });

    let tooltip = am5.Tooltip.new(root, {
      getFillFromSprite: false,
      autoTextColor: false,
      labelText: "[bold]{name}[/]\n [bold]Status:[/] {formattedState} {formattedPriority}",
    });

    tooltip.get("background").setAll({
      fill: am5.color(0x6a6a6a),
    });

    tooltip.label.setAll({
      fill: am5.color(0xffffff)
    });

    series.set("tooltip", tooltip);

    series.rectangles.template.setAll({
      fillOpacity: 1,
      cornerRadiusTL: 4,
      cornerRadiusTR: 4,
      cornerRadiusBL: 4,
      cornerRadiusBR: 4,
    });

    series.rectangles.template.adapters.add(
      "opacity",
      function (opacity, target) {
        if (target._dataItem._settings.depth > 1) {
          return 0.8;
        }
      }
    );

    // Add hover effect
    series.rectangles.template.events.on("hover", (event) => {
      const slice = event.target;
      if (slice.isActive || slice.isHiding || slice.isShowing) {
        return;
      }
      slice.setState("hover");
    });

    // Remove hover effect
    series.rectangles.template.events.on("out", (event) => {
      const slice = event.target;
      if (slice.isActive || slice.isHiding || slice.isShowing) {
        return;
      }
      slice.setState("default");
    });


    series.rectangles.template.events.on("click", function (e) {
      const dataContext = e.target.dataItem.dataContext;

      handleTopicSelected({
        name: dataContext.name,
        cybersecurity_topic_id: dataContext.cybersecurity_topic_id,
        cybersecurity_topic_status: dataContext.cybersecurity_topic_status,
      });
    });

    series.nodes.template.setAll({
      toggleKey: "none",
      cursorOverStyle: "pointer",
    });


    horizontalChartRef.current = series;

    return () => {
      root.dispose();
    };
  }, []);



  useEffect(() => {
    if (cyberSecurityTopics) {
      let data = chartFormatter();

      horizontalChartRef.current.data.setAll([data]);
      horizontalChartRef.current.set(
        "selectedDataItem",
        horizontalChartRef.current.dataItems[0]
      );
    }
  }, [cyberSecurityTopics]);

  return (
    <div
      id="horizontalChart"
      style={{ width: "100%", height: chartHeight && chartHeight }}
    ></div>
  );
};

export default HorizontalPartition;
