import React, { useState, useEffect, useCallback } from "react";
import Button from "../../../../components/UI/button/Button";
import { useSelector, useDispatch } from "react-redux";
import AssociateTopicsAccordion from "../associateTopicsAccordion/AssociateTopicsAccordion"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquarePlus, faSquareMinus } from '@fortawesome/free-regular-svg-icons'
import "./associateTopics.css"
import { chartColorList } from "../../../../util/functions/chartColorList";



const AssociateTopics = ({ technologiesData, handleCheckboxClick, handleAssociateTopicsAccordionToggle, technologiesListAccordionsState, handleSecurityTopicAccordionToggle }) => {
    const [accordionListData, setAccordionListData] = useState([]);

    const cyberSecurityTopics = useSelector((state) => state.cyberSecurityTopics.cyberSecurityTopics);

    const dispatch = useDispatch()
    const handleOpenAll = () => {
        handleSecurityTopicAccordionToggle(technologiesData.vendor.vendor_id, null, "open")
    }
    const handleCloseAll = () => {
        handleSecurityTopicAccordionToggle(technologiesData.vendor.vendor_id, null, "close")
    }

    const handleDisplayAccordionList = () => {
        const format = () => {

            return (
                accordionListData.map((el, index) => {

                    return (
                        <div key={index}>
                            <AssociateTopicsAccordion handleSecurityTopicAccordionToggle={handleSecurityTopicAccordionToggle} technologiesListAccordionsState={technologiesListAccordionsState} color={el.color} data={{ name: el.name, cybersecurity_topic_id: el.cybersecurity_topic_id, vendor_id: technologiesData.vendor.vendor_id }} checkBoxId={technologiesData.vendor.vendor_id + el.cybersecurity_topic_id} handleCheckboxClick={handleCheckboxClick} isChecked={el.associatedWithTechnology} header={el.name} expandable={el.expandable}>
                                {el.children[0] && formatChildren(el.children)}
                            </AssociateTopicsAccordion>
                        </div>
                    )

                })
            )
        }
        const formatChildren = (children) => {
            return (
                children.map((el, index) => {

                    return (
                        <div key={index}>
                            <AssociateTopicsAccordion handleSecurityTopicAccordionToggle={handleSecurityTopicAccordionToggle} technologiesListAccordionsState={technologiesListAccordionsState} color={el.color} isNested={true} data={{ name: el.name, cybersecurity_topic_id: el.cybersecurity_topic_id, vendor_id: technologiesData.vendor.vendor_id }} checkBoxId={technologiesData.vendor.vendor_id + el.cybersecurity_topic_id} handleCheckboxClick={handleCheckboxClick} isChecked={el.associatedWithTechnology} header={el.name} expandable={el.expandable}>
                                {el.children[0] && formatChildren(el.children)}
                            </AssociateTopicsAccordion>
                        </div>
                    )

                })
            )
        }
        return format()
    }

    useEffect(() => {
        if (cyberSecurityTopics) {
           
            const newAccordionList = createAccordionList();
            setAccordionListData(newAccordionList);
            handleDisplayAccordionList()
        }
    }, [cyberSecurityTopics]);


    const isAssociatedWithVendor = (topicCyberSecurityId) => {

        if (technologiesData && technologiesData.vendor && technologiesData.vendor.topics && technologiesData.vendor.topics[0]) {

            return technologiesData.vendor.topics.find((topic) => {

                return topic.cybersecurity_topic_id === topicCyberSecurityId
            })
        }
    }

    const createAccordionList = () => {

        const formatNestedData = (topics, colors, depth = 0) => {
            depth++;
            return topics.map((topic, key, array) => {
                isAssociatedWithVendor()

                let color = colors;
                // root level topics will have an array of colors.
                // The typeof array is "object"
                if (typeof colors === "object") {
                    color = colors[key]
                }
        
                return {
                    children: formatNestedData(topic.sub_topics, color, depth),
                    code: topic.code,
                    cybersecurity_topic_id: topic.cybersecurity_topic_id,
                    depth: depth,
                    color: color,
                    name: topic.name,
                    expandable: topic.sub_topics[0] ? true : false,
                    associatedWithTechnology: topic.vendor_assocs && topic.vendor_assocs[0] && isAssociatedWithVendor(topic.cybersecurity_topic_id) ? true : false

                }
            })
        }
        const topicList = cyberSecurityTopics.cybersecurity_topic_summary;
        const colorList = chartColorList(topicList.length);
        return formatNestedData(topicList, colorList);
    }

    const formatAssociateTopicButtonText = () => {
        if (technologiesListAccordionsState.get(technologiesData.vendor.vendor_id)?.associateTopicsAccordionToggle) {
            return (
                <div>
                    <FontAwesomeIcon icon={faSquareMinus} />
                    <span style={{ marginLeft: "10px" }}>
                        Associate Security Topics
                    </span>
                </div>
            )
        } else {
            return (
                <div>
                    <FontAwesomeIcon icon={faSquarePlus} />
                    <span style={{ marginLeft: "10px" }}>
                        Associate Security Topics
                    </span>
                </div>
            )
        }
    }

    return (

        <div >
            <Button type="primary"  style={{ fontSize: "14px", marginTop: "10px" }} text={formatAssociateTopicButtonText()} onClick={() => handleAssociateTopicsAccordionToggle(technologiesData.vendor.vendor_id, technologiesListAccordionsState.get(technologiesData.vendor.vendor_id)?.associateTopicsAccordionToggle)} />
            {technologiesListAccordionsState.get(technologiesData.vendor.vendor_id)?.associateTopicsAccordionToggle &&
                <div className="associate-topics-container">
                    <div>
                        <Button
                            text="Collapse All"
                            onClick={handleCloseAll}
                            style={{ fontSize: "12px", margin: "5px" }}
                        />
                        <Button
                            text="Expand All"
                            onClick={handleOpenAll}
                            style={{ fontSize: "12px", margin: "5px" }}
                        />
                    </div>
                    <div className="accordion-list">
                        {
                            handleDisplayAccordionList()
                        }
                    </div>
                </div>
            }
        </div>

    )
}

export default AssociateTopics;