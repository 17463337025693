import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCircleExclamation, faCircleXmark, faCircleCheck, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import "./alert.css"

const Alert = (
    {
        type = "",
        message = null,
        showIcon = true,
      
    }
) => {

    const handleShowIcon = () => {
        return (
            <span>  {type === "error" && <FontAwesomeIcon icon={faCircleXmark} size="1x" color="#FF4D4F" />}
                {type === "success" && <FontAwesomeIcon icon={faCircleCheck} size="1x" color="#52C41A" />}
                {type === "warning" && <FontAwesomeIcon icon={faCircleExclamation} size="1x" color="#FAAD14" />}
                {type === "info" && <FontAwesomeIcon icon={faCircleInfo} size="1x" color="#40A9FF" />}
            </span>
        )
    }



    return (

        <>
            <div className={`alert-container-${type}`} >
                <span>
                    {showIcon && handleShowIcon()}
                    <span style={{ paddingLeft: "10px" }}>{message}</span>
                </span>

            </div>

        </>
    )
}

export default Alert;

