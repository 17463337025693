import React, { useEffect, useState } from "react";
import Bubble from "../bubble/Bubble";
import SunBurst from "../sunburst/SunBurstChart";
import HorizontalPartition from "../horizontal/HorizontalPartition";
import Tree from "../tree/Tree";
import StatusForm from "../statusForm/StatusForm";
import MultiRangeSlider from "../../../../components/UI/slider/MultiRangeSlider";
import { useSelector } from "react-redux";

const ChartsWrapper = ({ showChart }) => {
  const [chartRange, setChartRange] = useState({ minVal: 1, maxVal: 2 });
  const [chartSecurityTopicsData, setChartSecurityTopicsData] = useState(null);
  const [statusFormData, setStatusFormData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const cyberSecurityTopics = useSelector((state) => state.cyberSecurityTopics.cyberSecurityTopics);


  const handleTopicSelected = ({ name, cybersecurity_topic_id, cybersecurity_topic_status }) => {
    setStatusFormData({ name, cybersecurity_topic_id, cybersecurity_topic_status });
    setShowModal(true)
  }


  const getChartDataByDepth = (topics, depth = 1) => {
    if (depth < chartRange.minVal) {
      let result = []
      topics.map((topic) => {
        result.push(...getChartDataByDepth(topic.sub_topics, depth + 1))
      })
      return result
    } else {
      return _getMaxDepthData(topics, depth)
    }
  };

  const handleCancel = () => {
    setShowModal(false)
  }


  const _getMaxDepthData = (data, depth) => {
    data.forEach(topic => {
      if (depth < chartRange.maxVal) {
        topic.sub_topics = _getMaxDepthData(topic.sub_topics, depth + 1)
      } else {
        topic.sub_topics = []
      }
    });

    return data
  };

  useEffect(() => {
    if (cyberSecurityTopics) {
      const securityData = structuredClone(cyberSecurityTopics?.cybersecurity_topic_summary)

      setChartSecurityTopicsData({
        cybersecurity_topic_summary: getChartDataByDepth(securityData)
      })
    }
  }, [chartRange, cyberSecurityTopics])

  return (
    <>
      {showModal &&
        <StatusForm
          formData={statusFormData}
          handleCancel={handleCancel}
        />
      }
      <MultiRangeSlider
        chartRange={chartRange}
        min={1}
        max={cyberSecurityTopics.max_depth}
        onMouseRelease={setChartRange}
      />

      {showChart === "sunburst" ? (
        <SunBurst
          cyberSecurityTopics={chartSecurityTopicsData}
          handleTopicSelected={handleTopicSelected}
        />
      ) : showChart === "bubble" ? (
        <Bubble
          cyberSecurityTopics={chartSecurityTopicsData}
          handleTopicSelected={handleTopicSelected}
        />
      ) : showChart === "horizontal" ? (
        <HorizontalPartition
          cyberSecurityTopics={chartSecurityTopicsData}
          handleTopicSelected={handleTopicSelected}
        />
      ) : showChart === "tree" ? (
        <Tree
          cyberSecurityTopics={chartSecurityTopicsData}
          handleTopicSelected={handleTopicSelected}
        />
      ) : null}
    </>
  );
};

export default ChartsWrapper;
