import { useEffect, useState } from "react"

import "./httpRequestCheckbox.css"
const HttpRequestCheckBox = ({ id = null, data = null, isChecked = false, handleCheckboxClick = null}) => {



  const [disabled, setDisabled]=useState(false)
  const [checked, setChecked]=useState(false)

  useEffect(() => {
    setChecked(isChecked)

  }, [isChecked])

const checkHandler =async(e)=>{
  setDisabled(true)
  setChecked(!checked)
 let response = await handleCheckboxClick(e,checked,data,id)

 setDisabled(response.disabled)

 setChecked(response.checkbox)

}


  return (
    <div className="http-checkbox-container">
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={checkHandler}
        disabled={disabled}
      />
      <label htmlFor={id}> </label>
    </div>
  )
}

export default HttpRequestCheckBox