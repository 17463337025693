// count => number of colors to return
// if count is greater than colorList length, the colorList will cycle
export const chartColorList = count => {
    let colorList = ["#5aaa95", "#5aa7aa", "#5a8faa", "#5a77aa", "#5a5faa", "#6d5aaa", "#855aaa", "#9d5aaa", "#aa5a9f", "#aa5a87", "#aa5a6f", "#aa5d5a", "#aa755a", "#aa8d5a", "#aaa55a", "#97aa5a", "#7faa5a", "#5aaa65", "#5aaa7d"];
  
    let iterations = 0;
    while (count > colorList.length && iterations < 10) {
      colorList = colorList.concat(colorList);
      iterations++;
    }
  
    return colorList.slice(0, count);
  }