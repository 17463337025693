import React, { useState } from "react";
import Button from "../../components/UI/button/Button";
import Input from "../../components/UI/Input/Input";
import { setPopUpAlert } from "../../redux/slices/alertSlice";
import { useSelector, useDispatch } from "react-redux";

const initialState = {
    name: "",
};

const AddTechnology = ({ formSubmit, loading }) => {
    const [addTechnologyFormData, setAddTechnologyFormData] = useState(initialState)
    const dispatch = useDispatch()

    const regex = /^(?=.*[a-zA-Z0-9])\s*\S/;

    const handleAddFormChange = (e) => {
        const { id, value } = e.target;
        setAddTechnologyFormData(prev => ({ ...prev, [id]: value }))
    }

    const handleAddButtonClick = (e) => {
        e.preventDefault();
        const trimmedName = addTechnologyFormData.name.trim();
        const isValid = regex.test(trimmedName);

        if (!isValid) {
            dispatch(setPopUpAlert({ message: "Technology name must contains at least one alphanumeric character", type: "error", length: 3000 }))
            return;
        } else {
            setAddTechnologyFormData({ name: trimmedName });
            formSubmit(e, { name: trimmedName }, setAddTechnologyFormData);
        }
    }

    return (
        <div>

            <h3 style={{ marginBottom: "10px" }}>Add New Technology</h3>

            <form onSubmit={handleAddButtonClick}>
                <div style={{ display: "flex", flexWrap: "wrap", maxWidth: "500px" }}>
                    <Input style={{ marginRight: "5px" }} type="text" label="Name" id="name"
                        value={addTechnologyFormData?.name}
                        onChange={handleAddFormChange}
                        required={true}
                    />
                    <Button loading={loading} disabled={loading ? true : false} type="submit-dark" text={"Add "} onClick={handleAddButtonClick} />
                </div>
            </form>
        </div>
    );
};


export default AddTechnology;
